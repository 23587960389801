/* eslint-disable react/prop-types */
import React, { useState } from "react"; // Import React and useState
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiBadge from "components/VuiBadge";
import VuiProgress from "components/VuiProgress";
import AdobeXD from "examples/Icons/AdobeXD";

// Import the video guides data directly
import videoGuidesData from './videoGuides.json';
import logoJira from "assets/images/small-logos/logo-jira.svg";
import Invision from "examples/Icons/Invision";
import VideoIcon from "examples/Icons/VideoIcon";


function Completion({ value, color }) {
  return (
    <VuiBox display="flex" flexDirection="column" alignItems="flex-start">
      <VuiTypography variant="button" color="white" fontWeight="medium" mb="4px">
        {value}%&nbsp;
      </VuiTypography>
      <VuiBox width="8rem">
        <VuiProgress value={value} color={color} sx={{ background: "#2D2E5F" }} label={false} />
      </VuiBox>
    </VuiBox>
  );
}

function VideoGuideTable() {
  const [expandedModule, setExpandedModule] = useState(null); // State to manage expanded module

  // Create rows for the main table with module names and course counts
  const rows = Object.keys(videoGuidesData.guide).map(module => {
    const moduleDetails = videoGuidesData.guide[module]; // Get details for the module
    const moduleName = moduleDetails[0].moduleName; // Extract the module name
    const courseCount = moduleDetails.length - 1; // Count of courses (subtracting the module name)

    return {
      "Module Name": (
        <VuiTypography
          variant="button"
          color="white"
          fontWeight="medium"
          onClick={() => setExpandedModule(expandedModule === module ? null : module)} // Toggle expand
          style={{ cursor: "pointer", textDecoration: "none" }}
        >
          {` ${moduleName}`}
        </VuiTypography>
      ),
      "Video Count": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          {`${courseCount}`}
        </VuiTypography>
      ),
      "isExpanded": expandedModule === module, // Track if the module is expanded
      "module": module // Store the module name for later use
    };
  });

  // Create sub-rows for the expanded module
  const subRows = expandedModule
    ? videoGuidesData.guide[expandedModule].slice(1).map(guide => ({
        "Guide Name": (
          <VuiBox display="flex" alignItems="center" pl="32px"> {/* Indent sub-row */}
            <VideoIcon size="20px" />
            <VuiTypography pl="16px" color="white" variant="button" fontWeight="medium">
              {guide.name}
            </VuiTypography>
          </VuiBox>
        ),
        "Category": (
          <VuiTypography variant="button" color="white" fontWeight="medium">
            {guide.category}
          </VuiTypography>
        ),
        "Module Completion": <Completion value={guide.completion} color={guide.completion === 100 ? "success" : "info"} />,

        "Action": (
          <VuiTypography
            component="a"
            href={guide.link}
            target="_blank"
            rel="noopener noreferrer"
            variant="caption"
            color="text"
            fontWeight="medium"
          >
            View
          </VuiTypography>
        ),
      })) 
    : [];

  // Combine main rows and their corresponding sub-rows
  const combinedRows = rows.flatMap(row => {
    const subRowComponents = row.isExpanded ? subRows : [];
    return [row, ...subRowComponents]; // Add the main row and its sub-rows
  });

  return {
    columns: [
      { name: "Module Name", align: "left" },
      { name: "Video Count", align: "center" },
      { name: "Guide Name", align: "left" }, // Column for sub-rows
      { name: "Category", align: "left" }, // Column for sub-rows
      { name: "Module Completion", align: "center" }, // Column for sub-rows
      { name: "Action", align: "center" }, // Column for sub-rows
    ],
    rows: combinedRows, // Use the combined rows
  };
}

export default VideoGuideTable;
