import React, { useState } from "react";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import DashboardLayout from "examples/LayoutContainers/TableDashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import imageResizeAndZip from "./data/imageResize"; // Import the image resizing and zipping function

function Iconsize() {
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]); // Capture the uploaded file
  };

  const handleGenerateIcons = async () => {
    if (file) {
      const zipFile = await imageResizeAndZip(file); // Call the resize and zip function
      const link = document.createElement('a');
      link.href = URL.createObjectURL(zipFile);
      link.download = 'app-icons.zip';
      link.click(); // Trigger the download
    } else {
      alert("Please upload an image first.");
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={5}>
        <Card>
          <VuiBox display="flex" justifyContent=" center" alignItems="center">
          <VuiTypography variant="xx-lg" color="white">
              App Store Icon Size Generator
            </VuiTypography>
            <br/>
          </VuiBox>

          <VuiBox py={2} display="flex" flexDirection="column" alignItems="center">
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              style={{
                width: '250px',       // Adjust width as needed
                height: '50px',       // Adjust height as needed
                borderRadius: '8px',  // Add border-radius to the input
                padding: '10px',      // Add some padding for better appearance
                backgroundColor: '#2D2E5F', // Dark background color
                color: 'white',       // Text color to white
                cursor: 'pointer',    // Pointer cursor on hover
                textAlign: 'center',
                border: '2px solid #ffffff', // Optional border
                marginTop: '15px'
              }}
            />
            <Button
              onClick={handleGenerateIcons}
              variant="outlined"
              color="white"
              sx={{
                border:'none',
                width: '250px',        // Same width as the input for consistency
                height: '50px',        // Adjust height as needed
                mt: 5,                 // Margin-top for spacing between input and button
                color: 'white',        // White text for the button
                borderRadius: '8px',   // Border-radius to match input
                fontSize: '18px',      // Larger font size for better readability
                backgroundColor: '#1976d2', // Primary button color (or customize as needed)
                '&:hover': {
                  backgroundColor: '#115293', // Darker color on hover
                },
              }}
            >
              Generate Icon Sizes
            </Button>
          </VuiBox>

        </Card>
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Iconsize;
