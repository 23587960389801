// src/components/PricingTable.js

import React, { useEffect } from 'react';

const PricingTable = () => {
  useEffect(() => {
    // This will ensure the pricing table is loaded
    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/pricing-table.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Clean up the script when the component unmounts
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      <h2>Choose Your Plan</h2>
      <stripe-pricing-table
        pricing-table-id="prctbl_1QCFqJCppa4dQE4DSmqN01ky"
        publishable-key="pk_live_51OF11JCppa4dQE4D6Rd7qb0NkEasCjQ512jiF5fAvvQQQ7AH0epTzFJyEvtzoEDT9Hm3UO3uupDzPxbh0ncNU0qq003Cw8fHdd">
      </stripe-pricing-table>
    </div>
  );
};

export default PricingTable;
