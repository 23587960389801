import { useEffect, useState } from "react";
// @mui material components
import Card from "@mui/material/Card";
import { TextField, } from '@mui/material';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog"; // Import Dialog for confirmation
import DialogActions from "@mui/material/DialogActions"; // Import DialogActions
import DialogContent from "@mui/material/DialogContent"; // Import DialogContent
import DialogContentText from "@mui/material/DialogContentText"; // Import DialogContentText
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CircularProgress from "@mui/material/CircularProgress"; // Import CircularProgress for loading state

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

// Vision UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/TableDashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Axios for API requests
import axios from "axios";
import { getFirestore, doc, getDoc, setDoc } from "firebase/firestore"; // Import Firestore functions
import { getAuth } from "firebase/auth"; // Import Auth to get user ID
import { useMediaQuery } from '@mui/material'; // Import useMediaQuery

function AiAppTool() {
  const [ideas, setIdeas] = useState([]);
  const [selectedIdea, setSelectedIdea] = useState(null);
  const [fullResponse, setFullResponse] = useState(""); // New state for full response
  const [showTable, setShowTable] = useState(false);
  const [loading, setLoading] = useState(false); // State for loading
  const [unsavedChanges, setUnsavedChanges] = useState(false); // Track unsaved changes
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false); // State for dialog
  const [projectSaved, setProjectSaved] = useState(false); // New state to track if the project has been saved

  const apiKey = process.env.REACT_APP_API_KEY;



  const auth = getAuth();
  const user = auth.currentUser; // Get the currently authenticated user

  // User check moved here after defining user
  useEffect(() => {
    if (!user) {
      console.error("User is not authenticated.");
      return; // Exit if no user is authenticated
    }
  }, [user]);


  const handleChooseIdea = async (idea) => {
    setSelectedIdea(idea);
    setShowTable(false);
    await fetchIdeaDetails(idea);
  };

  const handleCreateProject = async () => {
    setProjectSaved(false); // Set the project as saved

    if (fullResponse && unsavedChanges) {
      setConfirmDialogOpen(true); // Open confirmation dialog if there are unsaved changes
      return;
    }

    setFullResponse(""); // Clear any previous full response

    setLoading(true); // Set loading to true when starting the request
    try {
      console.log("Sending request to create project...");
      console.log("API Key:", apiKey); // Log the API key for debugging

      const response = await axios.post('https://api.openai.com/v1/chat/completions', {
        model: "gpt-3.5-turbo",
        messages: [
          {
            role: "user",
            content: "Generate 10 simple app/game ideas with descriptions make it a mixture of games and apps. format it so my code can read the name and description in with this line: const [name, ...description] = idea.split'(':'); // Split on the first colon ",
          }
        ],
      }, {
        headers: {
          'Authorization': `Bearer ${apiKey}`,
          'Content-Type': 'application/json',
        },
      });

      console.log("Response received:", response.data);

      // Ensure there are choices and content
      if (response.data.choices && response.data.choices.length > 0) {
        const ideasArray = response.data.choices[0].message.content.split("\n\n").map((idea, index) => {
          const [name, ...description] = idea.split(": "); // Split on the first colon
          return {
            name: name.trim(),
            description: description.join(": ").trim(),
            index,
          };
        });

        setIdeas(ideasArray);
        setShowTable(true);
      } else {
        console.error("No ideas found in the response.");
      }
    } catch (error) {
      // Enhanced error logging
      logError(error);
    } finally {
      setLoading(false); // Set loading to false when request is completed (success or error)
    }
  };

  const fetchIdeaDetails = async (idea) => {
    setLoading(true); // Set loading to true when starting the request
    try {
      console.log(`Fetching details for idea: ${idea.name}...`);
      const response = await axios.post('https://api.openai.com/v1/chat/completions', {
        model: "gpt-3.5-turbo",
        messages: [
          {
            role: "user",
            content: `Generate detailed information for the app idea: ${idea.name}. Include project overview, pages, functionality, ads placement, recommended ad frequency, color palette (detailed recommendation on what elements should be what colour and list each colour with hex as well), app store name idea, app store title and description, logo idea.`,
          }
        ],
      }, {
        headers: {
          'Authorization': `Bearer ${apiKey}`,
          'Content-Type': 'application/json',
        },
      });

      console.log("Details response received:", response.data);

      if (response.data.choices && response.data.choices.length > 0) {
        const detailsContent = response.data.choices[0].message.content;

        setFullResponse(detailsContent); // Set the full response
        setUnsavedChanges(true); // Mark changes as unsaved
      } else {
        console.error("No details found in the response.");
      }
    } catch (error) {
      logError(error);
    } finally {
      setLoading(false); // Set loading to false when request is completed (success or error)
    }
  };

  const handleSaveProject = async () => {
    const db = getFirestore();
    const auth = getAuth();
    const user = auth.currentUser; // Get the authenticated user again

    if (!user) {
      console.error("User is not authenticated.");
      return;
    }

    const userId = user.uid;
    const userDocRef = doc(db, "users", userId);

    try {
      const userDocSnapshot = await getDoc(userDocRef);

      const projectName = selectedIdea?.name ? selectedIdea.name.replace(/^\d+\.\s*/, '') : selectedIdea; // Use newIdea if selectedIdea is not present

      const newProject = {
        name: projectName,   // Use cleaned project name
        details: fullResponse, // Full project details
      };
      if (userDocSnapshot.exists()) {
        console.log("User document found. Adding project...");
        await setDoc(userDocRef, {
          projects: [...(userDocSnapshot.data().projects || []), newProject], // Add the new project
        }, { merge: true });
      } else {
        console.log("User document not found. Creating a new document...");
        await setDoc(userDocRef, {
          projects: [newProject], // Create a new projects array with the new project
        });
      }

      console.log("Project saved:", newProject);
      setUnsavedChanges(false);
      setProjectSaved(true); // Set the project as saved

    } catch (error) {
      console.error("Error saving project:", error);
    }
  };

  const handleConfirmDialogClose = (confirm) => {
    if (confirm) {
      console.log("Confirmed to discard unsaved changes.");
      handleDiscard(); // Call handleDiscard directly
    } else {
      console.log("Cancelled the discard operation.");
      setConfirmDialogOpen(false); // Close dialog if cancel is clicked
    }
  };

  const handleDiscard = async () => {
    console.log("Discard operation initiated.");
    setLoading(true); // Set loading to true while processing

    try {
      // Reset state to clear previous project details
      setFullResponse(""); // Clear previous full response
      setIdeas([]); // Clear the ideas list
      setSelectedIdea(null); // Clear the selected idea
      setShowTable(false); // Hide the ideas table

      await handleCreateProject(); // Await the creation of the project
      console.log("Project created after discarding unsaved changes.");
    } catch (error) {
      console.error("Error during discard operation:", error); // Log any errors
    } finally {
      setLoading(false); // Always set loading to false at the end
      setConfirmDialogOpen(false); // Close the dialog after the operation
    }
  };



  const logError = (error) => {
    if (error.response) {
      console.error("Error response data:", error.response.data);
      console.error("Error response status:", error.response.status);
      console.error("Error response headers:", error.response.headers);
    } else if (error.request) {
      console.error("No response received:", error.request);
    } else {
      console.error("Error setting up request:", error.message);
    }
  };

  const fetchIdeaDetailsManual = async (idea) => {
    setLoading(true); // Set loading to true when starting the request
    try {
      console.log(`Fetching details for idea: ${idea}...`); // Log the idea directly as a string
      const response = await axios.post('https://api.openai.com/v1/chat/completions', {
        model: "gpt-3.5-turbo",
        messages: [
          {
            role: "user",
            content: `Generate detailed information for the app idea: ${idea}. Include project overview, pages, functionality, ads placement, recommended ad frequency, color palette (detailed recommendation on what elements should be what colour and list each colour with hex as well), app store name idea, app store title and description, logo idea.`,
          }
        ],
      }, {
        headers: {
          'Authorization': `Bearer ${apiKey}`,
          'Content-Type': 'application/json',
        },
      });

      console.log("Details response received:", response.data);

      if (response.data.choices && response.data.choices.length > 0) {
        const detailsContent = response.data.choices[0].message.content;

        setFullResponse(detailsContent); // Set the full response
        setUnsavedChanges(true); // Mark changes as unsaved
      } else {
        console.error("No details found in the response.");
      }
    } catch (error) {
      logError(error);
    } finally {
      setLoading(false); // Set loading to false when request is completed (success or error)
    }
  };


  const [newIdea, setNewIdea] = useState('');

  // Optional: Function to handle submitting the idea
  const handleSubmitManualIdea = () => {
    console.log("newIdea: " + newIdea);
    if (newIdea.trim()) {
      console.log("newIdea: " + newIdea);
      setSelectedIdea(newIdea);  // Pass the idea to the parent function

      fetchIdeaDetailsManual(newIdea);  // Pass the idea to the parent function
      setNewIdea('');         // Clear the input field after submission
    }
  };

  const resetState = () => {
    setFullResponse(null);
    setShowTable(false);
    setSelectedIdea(null);
    setNewIdea(""); // Clear any previous input
    setProjectSaved(false); // Reset saved state if needed
  };

  const isMobile = useMediaQuery('(max-width:600px)'); // Adjust breakpoint as needed

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        <VuiBox mb={3}>
          <Card>
            <VuiBox display="flex" justifyContent="space-between" alignItems="center" mb="22px">
              <VuiTypography variant="lg" color="white">AI App Tool</VuiTypography>
            </VuiBox>

            <VuiBox>
              {/* If loading, show CircularProgress */}
              {loading ? (
                <CircularProgress />
              ) : (
                <VuiBox mt={3}>
                  {/* If fullResponse exists, show Reset button, otherwise show TextField and buttons */}
                  {fullResponse ? (
                    <Button
                      style={{ color: 'white' }}
                      variant="contained"
                      onClick={resetState} // Reset to initial state
                    >
                      Reset
                    </Button>
                  ) : (
                    <>
                      <VuiBox 
                        display={isMobile ? "block" : "flex"} // Change layout based on screen size
                        justifyContent={isMobile ? "flex-start" : "space-between"} 
                        alignItems={isMobile ? "flex-start" : "center"}
                      >
                        {/* Create Project Button */}
                        <Button
                          style={{ color: 'white', marginBottom: isMobile ? '10px' : '0', width: isMobile? '100%': "200px" }} // Add margin for mobile
                          variant="contained"
                          onClick={handleCreateProject}
                        >
                          Create AI Project
                        </Button>
                        <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}>
                          <TextField
                            placeholder="New Idea"
                            value={newIdea} // Bind state to the input field
                            onChange={(e) => setNewIdea(e.target.value)} // Update state on input change
                            variant="outlined"
                            style={{ marginRight: isMobile ? "0" : "10px", marginBottom: isMobile ? '10px' : '0' }} // Add margin for mobile
                          />
                          {/* Create Project Manually Button */}
                          <Button
                            style={{ color: 'white' }}
                            variant="contained"
                            onClick={handleSubmitManualIdea}
                          >
                            Create Project Manually
                          </Button>
                        </div>
                      </VuiBox>
                    </>
                  )}
                </VuiBox>
              )}

              {/* Show the table with ideas if fullResponse doesn't exist and showTable is true */}
              {!loading && !fullResponse && showTable && (
                <VuiBox color="text">
                  <VuiBox mt={3}>
                    {ideas.map((idea) => (
                      <Card key={idea.index} style={{ backgroundColor: '#1A1A1A', marginBottom: '16px', padding: '16px' }}>
                        <VuiBox display="flex" justifyContent="space-between" alignItems="center">
                          <VuiTypography variant="body1" color="white" fontWeight="medium">
                            {idea.name}
                          </VuiTypography>
                          <Button
                            variant="contained"
                            style={{ backgroundColor: "white", color: "black" }} // Button with white background and black text
                            onClick={() => handleChooseIdea(idea)}
                          >
                            Choose Idea
                          </Button>
                        </VuiBox>
                      </Card>
                    ))}
                  </VuiBox>
                </VuiBox>
              )}

              {/* Show full response if it exists */}
              {fullResponse && (
                <VuiBox mt={3} display="flex" justifyContent="center">
                  <Card style={{ width: '100%', padding: '16px', backgroundColor: '#1A1A1A' }}>
                    <VuiBox display="flex" justifyContent="space-between" alignItems="end" width="100%" mt={2}>
                      <VuiTypography variant="h6" color="white" fontWeight="bold">
                        {selectedIdea?.name ? selectedIdea.name.replace(/^\d+\.\s*/, '') : selectedIdea}
                      </VuiTypography>
                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: projectSaved ? '#1dac45' : 'white',
                          color: projectSaved ? 'white' : 'black'
                        }}
                        onClick={handleSaveProject}
                        disabled={projectSaved} // Disable if already saved
                      >
                        {projectSaved ? 'Project Saved' : 'Save Project'}
                      </Button>
                    </VuiBox>
                    {fullResponse.split('\n').map((line, index) => {
                      const separatorIndex = line.indexOf(": ");
                      if (separatorIndex !== -1) {
                        const header = line.slice(0, separatorIndex).trim();
                        const content = line.slice(separatorIndex + 2).trim();
                        return (
                          <VuiBox key={index} mt={2}>
                            <VuiTypography variant="h6" color="white" fontWeight="bold">{header}:</VuiTypography>
                            <VuiTypography variant="body2" color="white" mt={1}>{content}</VuiTypography>
                          </VuiBox>
                        );
                      }
                      return (
                        <VuiBox key={index} mt={2}>
                          <VuiTypography variant="body2" color="white" mt={1}>{line}</VuiTypography>
                        </VuiBox>
                      );
                    })}
                  </Card>
                </VuiBox>
              )}
            </VuiBox>

            {/* Confirmation Dialog */}
            <Dialog open={confirmDialogOpen}>
              <DialogContent>
                <DialogContentText>
                  You have unsaved changes. Are you sure you want to discard them and create a new project?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => handleConfirmDialogClose(false)}>Cancel</Button>
                <Button
                  onClick={() => handleConfirmDialogClose(true)} // Call handleDiscard when clicked
                  color="primary"
                  disabled={loading} // Disable if loading
                >
                  Discard
                </Button>
              </DialogActions>
            </Dialog>
          </Card>

        </VuiBox>
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AiAppTool;
