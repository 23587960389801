/*!

=========================================================
* Vision UI Free React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-react/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import { useState, useEffect } from "react";

// react-router components
import { useLocation, Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import starIcon from "assets/images/star.png";

// Vision UI Dashboard React example components
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { IoSearch } from "react-icons/io5";
import { IoCard } from "react-icons/io5";
import { IoNotifications } from "react-icons/io5";
import { TiThMenu } from "react-icons/ti";
import { IoIosHome } from "react-icons/io";
import { IoLogOut } from "react-icons/io5";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Vision UI Dashboard React context
import {
  useVisionUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";

// Images
import team2 from "assets/images/team-2.jpg";
import logoSpotify from "assets/images/small-logos/logo-spotify.svg";
import zIndex from "@mui/material/styles/zIndex";
function DashboardNavbar({ absolute, light, isMini }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useVisionUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);
  const [query, setQuery] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    setNavbarType(fixedNavbar ? "sticky" : "static");

    const handleTransparentNavbar = () => {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    };

    window.addEventListener("scroll", handleTransparentNavbar);
    handleTransparentNavbar();

    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);




  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = (page) => {
    setOpenMenu(false);
    if (typeof page === 'string') navigate(page);
  };

  const handleLogout = () => {
    navigate("/authentication/logout"); // Navigate to dashboard on successful sign-in
  }

  const handleNavigateToBilling = () => {
    navigate("/billing"); // Navigate to dashboard on successful sign-in
  };
  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem
        image={<img src={starIcon} alt="person" />}
        title={["Complete", "Full Course"]}
        date="Task 1"
        onClick={() => handleCloseMenu('/Full-Course')} />
      <NotificationItem
        image={<img src={starIcon} alt="person" />}
        title={["Create", "AI App Ideas"]}
        date="Task 2"
        onClick={() => handleCloseMenu('/ai-app-tool')} />

      <NotificationItem
        image={<img src={starIcon} alt="person" />}
        title={["Choose", "Your Perfect Project"]}
        date="Task 3"
        onClick={() => handleCloseMenu('/projects')} />

      <NotificationItem
        image={<img src={starIcon} alt="person" />}
        title={["Design", "Your App"]}
        date="Task 4"
        onClick={() => handleCloseMenu('/Canva')} />

      <NotificationItem
        image={<img src={starIcon} alt="person" />}
        title={["Start", "Development"]}
        date="Task 5"
        onClick={() => handleCloseMenu('/tracker')} />

      <NotificationItem
        image={<img src={starIcon} alt="person" />}
        title={["Create", "Xcode Icons"]}
        date="Task 6"
        onClick={() => handleCloseMenu('/iconsize')} />

      <NotificationItem
        image={<img src={starIcon} alt="person" />}
        title={["Create", "App Store Visuals"]}
        date="Task 7"
        onClick={() => handleCloseMenu('/Canva')} />

      <NotificationItem
        image={<img src={starIcon} alt="person" />}
        title={["Reach", "Users Organically"]}
        date="Task 8"
        onClick={() => handleCloseMenu('/chrome')} />

      <NotificationItem
        image={<img src={starIcon} alt="person" />}
        title={["Manage", "Your Projects"]}
        date="Task 9"
        onClick={() => handleCloseMenu('/tracker')} />

      <NotificationItem
        image={<img src={starIcon} alt="person" />}
        title={["Sell", "Your Projects"]}
        date="Task 10"
        onClick={() => handleCloseMenu('/flippa')} />


    </Menu>
  );

  const featurePages = [
    // dashboard
    { feature: "Dashboard", route: "/dashboard" },
    { feature: "This Month's Revenue", route: "/dashboard" },
    { feature: "Last Month's Revenue", route: "/dashboard" },
    { feature: "Total Users", route: "/dashboard" },
    { feature: "Potential Sale Revenue", route: "/dashboard" },
    { feature: "Sales Overview", route: "/dashboard" },
    { feature: "App Idea Quick Notes", route: "/dashboard" },


    //full course
    { feature: "Full Course", route: "/Full-Course" },
    { feature: "Video Course", route: "/Full-Course" },
    { feature: "Guides", route: "/Full-Course" },
    { feature: "Module 1: Foundation and Setup", route: "/Full-Course" },
    { feature: "Module 2: Learning Core Skills	", route: "/Full-Course" },
    { feature: "Module 3: AI Integration & Rapid App Development	", route: "/Full-Course" },
    { feature: "Module 4: Marketing & Monetisation Strategies	", route: "/Full-Course" },

    //ai app tool
    { feature: "AI App Tool", route: "/ai-app-tool" },
    { feature: "Create AI Project", route: "/ai-app-tool" },
    { feature: "Create Project Manually", route: "/ai-app-tool" },


    //projects
    { feature: "Projects", route: "/projects" },
    { feature: "Saved Projects", route: "/projects" },


    //creation tracker
    { feature: "Creation Tracker", route: "/tracker" },
    { feature: "Revenue Tracker", route: "/tracker" },
    { feature: "Progress Tracker", route: "/tracker" },
    { feature: "Flippa Tracker", route: "/tracker" },
    { feature: "Admob Tracker", route: "/tracker" },
    { feature: "Github Link", route: "/tracker" },
    { feature: "App Store Link", route: "/tracker" },
    { feature: "App Store Connect Link", route: "/tracker" },


    //canva
    { feature: "Canva Templates", route: "/canva" },
    { feature: "App Sketch Template	", route: "/canva" },
    { feature: "App Store Screenshot Template	", route: "/canva" },
    { feature: "App Ad Creative Template	", route: "/canva" },

    //icons
    { feature: "App Store Icon Size Generator", route: "/iconsize" },
    { feature: "Generate Image Sizes", route: "/iconsize" },


    //social media
    { feature: "Chrome extensions", route: "/chrome" },
    { feature: "AI Engagement Tool", route: "/chrome" },
    { feature: "Instagram Content Grabber", route: "/chrome" },
    { feature: "Ultra Focus Tool", route: "/chrome" },

    //flippa
    { feature: "Flippa Templates", route: "/flippa" },
    { feature: "Pitch deck", route: "/flippa" },
    { feature: "Sales deck", route: "/flippa" },
    { feature: "Finanical Deck", route: "/flippa" },


    //billing
    { feature: "Billing", route: "/billing" },
    { feature: "Email address", route: "/billing" },
    { feature: "Billing Plan", route: "/billing" },
    { feature: "Password reset", route: "/billing" },

  ];


  // Function to handle search input change
  // Function to handle search input change
  const handleSearchChange = (e) => {
    const value = e.target.value; // Capture the current input value
    setQuery(value); // Update the query state

    // Filter results based on the current query
    const results = featurePages.filter((item) =>
      item.feature.toLowerCase().includes(value.toLowerCase())
    );

    // Update filteredResults with the filtered items
    setFilteredResults(results);
  };

  const handleResultClick = (route) => {
    setQuery(""); // Clear search input
    setFilteredResults([]); // Clear results
    navigate(route); // Navigate to the selected page
  };

  const handleHomeClick = () => {
    navigate("/dashboard"); // Redirect to the dashboard route
  };


  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
      <VuiBox 
  display="flex" 
  alignItems="center" 
  color="inherit" 
  mb={{ xs: 1, md: 0 }} 
  sx={(theme) => navbarRow(theme, { isMini })}  // Assuming `navbarRow` is your styling function
>
<div onClick={handleHomeClick} style={{ cursor: "pointer" }}> {/* Make the icon clickable */}
        <IoIosHome style={{ marginRight: "8px" }} />
      </div>
</VuiBox>
        {isMini ? null : (
          <VuiBox sx={(theme) => navbarRow(theme, { isMini })}>
            <div>

              <VuiBox pr={1}>
                
                <VuiInput
                  placeholder="Search features..."
                  value={query}
                  onChange={handleSearchChange}
                  sx={({ breakpoints }) => ({
                    [breakpoints.down("sm")]: { maxWidth: "80%" },
                    [breakpoints.only("sm")]: { maxWidth: "80%" },
                    backgroundColor: "info.main !important",
                    color:"white",
                    zIndex:'3'
                  })}
                />
              </VuiBox>
              {/* Display search results as a dropdown */}
              {filteredResults.length > 0 && (
                <ul style={{ listStyleType: "none", padding: 0, marginTop: "10px", backgroundColor: "#fff" }}>
                  {query && filteredResults.length > 0 && ( // Only show if there's a query and results
                    <ul style={{ listStyleType: "none", padding: 0, marginTop: "10px", backgroundColor: "#fff" }}>
                      {filteredResults.map((result) => (
                        <li
                          key={result.route} // Unique key for each list item
                          style={{
                            cursor: "pointer",
                            padding: "10px",
                            backgroundColor: "#f0f0f0",
                            margin: "5px 0",
                          }}
                          onClick={() => handleResultClick(result.route)}
                        >
                          {result.feature}
                        </li>
                      ))}
                    </ul>
                  )}
                </ul>
              )}

            </div>
            <VuiBox color={light ? "white" : "inherit"}>
              {/* <Link to="/authentication/sign-in">
                <IconButton sx={navbarIconButton} size="small">
                  <Icon
                    sx={({ palette: { dark, white } }) => ({
                      color: light ? white.main : dark.main,
                    })}
                  >
                    account_circle
                  </Icon>
                  <VuiTypography
                    variant="button"
                    fontWeight="medium"
                    color={light ? "white" : "dark"}
                  >
                    Sign in
                  </VuiTypography>
                </IconButton>
              </Link> */}
              <IconButton
                size="small"
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <TiThMenu />

              </IconButton>
              
              <IconButton
                size="small"
                color="white"
                sx={navbarIconButton}
                onClick={handleNavigateToBilling}
              >
                <IoCard/>
              </IconButton>
              <IconButton
                size="small"
                color="white"
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenMenu}
              >
                <IoNotifications/>
              </IconButton>

              <IconButton
                size="small"
                color="white"
                sx={navbarIconButton}
          
                variant="contained"
                onClick={handleLogout}
              >
                <IoLogOut/>
              </IconButton>
              {renderMenu()}
            </VuiBox>
          </VuiBox>
        )}
      </Toolbar>

    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
