/*!

=========================================================
* Vision UI Free React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-react/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

/* eslint-disable react/prop-types */
// @mui material components
// import Icon from "@mui/material/Icon";

// // Vision UI Dashboard React components
// import VuiBox from "components/VuiBox";
// import VuiTypography from "components/VuiTypography";
// import VuiProgress from "components/VuiProgress";

// // Images
// import AdobeXD from "examples/Icons/AdobeXD";
// import Atlassian from "examples/Icons/Atlassian";
// import Slack from "examples/Icons/Slack";
// import Spotify from "examples/Icons/Spotify";
// import Jira from "examples/Icons/Jira";
// import Invision from "examples/Icons/Invision";
// import logoSpotify from "assets/images/small-logos/logo-spotify.svg";
// import logoInvesion from "assets/images/small-logos/logo-invision.svg";
// import logoJira from "assets/images/small-logos/logo-jira.svg";
// import logoSlack from "assets/images/small-logos/logo-slack.svg";
// import logoWebDev from "assets/images/small-logos/logo-webdev.svg";
// import logoXD from "assets/images/small-logos/logo-xd.svg";

// function Completion({ value, color }) {
//   return (
//     <VuiBox display="flex" flexDirection="column" alignItems="flex-start">
//       <VuiTypography variant="button" color="white" fontWeight="medium" mb="4px">
//         {value}%&nbsp;
//       </VuiTypography>
//       <VuiBox width="8rem">
//         <VuiProgress value={value} color={color} sx={{ background: "#2D2E5F" }} label={false} />
//       </VuiBox>
//     </VuiBox>
//   );
// }

// const action = (
//   <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small">
//     more_vert
//   </Icon>
// );

// export default {
//   columns: [
//     { name: "Guide Name", align: "left",  },
//     { name: "Category", align: "left" },
//     { name: "Module", align: "left" },
//     { name: "Module Completion", align: "center" },
//     { name: "action", align: "center" },
//   ],

//   rows: [
//     {
//       "Guide Name": (
//         <VuiBox display="flex" alignItems="center">
//           <AdobeXD size="20px" />
//           <VuiTypography pl="16px" color="white" variant="button" fontWeight="medium">
//           Introduction to IOS Development
//           </VuiTypography>
//         </VuiBox>
//       ),
//       Category: (
//         <VuiTypography variant="button" color="white" fontWeight="medium">
//           $14,000
//         </VuiTypography>
//       ),
//       Module: (
//         <VuiTypography variant="button" color="white" fontWeight="medium">
//           Module 1: Foundation and Setup
//         </VuiTypography>
//       ),
//       "Module Completion": <Completion value={60} color="info" />,
//       action,
//     },

//      {
//       "Guide Name": (
//         <VuiBox display="flex" alignItems="center">
//           <AdobeXD size="20px" />
//           <VuiTypography pl="16px" color="white" variant="button" fontWeight="medium">
//           Introduction to IOS Development
//           </VuiTypography>
//         </VuiBox>
//       ),
//       Category: (
//         <VuiTypography variant="button" color="white" fontWeight="medium">
//           $14,000
//         </VuiTypography>
//       ),
//       Module: (
//         <VuiTypography variant="button" color="white" fontWeight="medium">
//           Module 1: Foundation and Setup
//         </VuiTypography>
//       ),
//       "Module Completion": <Completion value={60} color="info" />,
//       action,
//     },
   
//   ],
// };
/*!
/* eslint-disable react/prop-types */
import React, { useState } from "react"; // Import React and useState
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiProgress from "components/VuiProgress";
import AdobeXD from "examples/Icons/AdobeXD";

// Import the guides data directly
import guidesData from './guides.json';
import FileIcon from "examples/Icons/FileIcon";

function Completion({ value, color }) {
  return (
    <VuiBox display="flex" flexDirection="column" alignItems="flex-start">
      <VuiTypography variant="button" color="white" fontWeight="medium" mb="4px">
        {value}%&nbsp;
      </VuiTypography>
      <VuiBox width="8rem">
        <VuiProgress value={value} color={color} sx={{ background: "#2D2E5F" }} label={false} />
      </VuiBox>
    </VuiBox>
  );
}

const GuideTable = () => {
  const [expandedModule, setExpandedModule] = useState(null); // State to manage expanded module

  // Create rows for the main table with module names and guide counts
  const rows = Object.keys(guidesData.guide).map(module => {
    const moduleDetails = guidesData.guide[module]; // Get details for the module
    const moduleName = moduleDetails[0].moduleName; // Extract the module name
    const guideCount = moduleDetails.length - 1; // Count of guides (subtracting the module name)

    return {
      "Module Name": (
        <VuiTypography
          variant="button"
          color="white"
          fontWeight="medium"
          onClick={() => setExpandedModule(expandedModule === module ? null : module)} // Toggle expand
          style={{ cursor: "pointer", textDecoration: "none" }}
        >
          {` ${moduleName}`}
        </VuiTypography>
      ),
      "Guide Count": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          {`${guideCount}`}
        </VuiTypography>
      ),
      "isExpanded": expandedModule === module, // Track if the module is expanded
    };
  });

  // Create sub-rows for the expanded module
  const subRows = expandedModule ? guidesData.guide[expandedModule].slice(1).map(guide => ({
    "Guide Name": (
      <VuiBox display="flex" alignItems="center" pl="32px"> {/* Indent sub-row */}
        <FileIcon size="20px" />
        <VuiTypography pl="16px" color="white" variant="button" fontWeight="medium">
          {guide.name}
        </VuiTypography>
      </VuiBox>
    ),
    "Category": (
      <VuiTypography variant="button" color="white" fontWeight="medium">
        {guide.category}
      </VuiTypography>
    ),
    "Module Completion": <Completion value={guide.completion} color={guide.completion === 100 ? "success" : "info"} />,
    "Action": (
      <a href={guide.link} target="_blank" rel="noopener noreferrer" style={{ color: 'white' }}>
        File
      </a>
    ),
  })) : [];

  // Combine main rows and their corresponding sub-rows
  const combinedRows = rows.flatMap((row, index) => {
    const subRowComponents = row.isExpanded ? subRows : [];
    return [row, ...subRowComponents]; // Add the main row and its sub-rows
  });

  return {
    columns: [
      { name: "Module Name", align: "left" },
      { name: "Guide Count", align: "center" },
      { name: "Guide Name", align: "left" }, // Column for sub-rows
      { name: "Category", align: "left" }, // Column for sub-rows
      { name: "Module Completion", align: "center" }, // Column for sub-rows
      { name: "Action", align: "center" }, // Column for sub-rows
    ],
    rows: combinedRows, // Use the combined rows
  };
};

export default GuideTable;
