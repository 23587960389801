import { useState } from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";

// Icons
import { FaApple, FaFacebook, FaGoogle } from "react-icons/fa";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import VuiSwitch from "components/VuiSwitch";
import GradientBorder from "examples/GradientBorder";
import CircularProgress from "@mui/material/CircularProgress"; // Add CircularProgress for the spinner

// Vision UI Dashboard assets
import radialGradient from "assets/theme/functions/radialGradient";
import rgba from "assets/theme/functions/rgba";
import palette from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import { auth, db } from '../../../firebase';
import { doc, getDoc, setDoc, getFirestore } from "firebase/firestore"; // Import Firestore functions

import { createUserWithEmailAndPassword } from "firebase/auth";

import bgSignIn from "assets/images/signUpImage.png";
import { useMediaQuery } from '@mui/material'; // Import useMediaQuery

function SignIn() {
  const [rememberMe, setRememberMe] = useState(true);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false); // Add loading state
  const isMobile = useMediaQuery('(max-width:600px)'); // Adjust breakpoint as needed
  const navigate = useNavigate(); // Hook for navigation

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const handleSignUp = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true); // Show spinner when sign up starts

    if (!name || !email || !password) {
      setError("All fields are required.");
      setLoading(false); // Hide spinner when there's an error
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      console.log("User signed up:", userCredential.user);

      const user = auth.currentUser;

      if (!user) {
        console.error("User is not authenticated.");
        setLoading(false); // Hide spinner if user is not authenticated
        return;
      }

      const userId = user.uid;
      const userDocRef = doc(db, "users", userId);

      try {
        const userDocSnapshot = await getDoc(userDocRef);

        if (userDocSnapshot.exists()) {
          console.log("User document found. Adding project...");
          await setDoc(userDocRef, {
            Name: name,
          }, { merge: true });
        } else {
          console.log("User document not found. Creating a new document...");
          await setDoc(userDocRef, {
            Name: name,
          });
        }

      } catch (error) {
        console.error("Error saving user:", error);
      }

      setLoading(false); // Hide spinner after successful signup
      navigate("/dashboard");

    } catch (err) {
      console.error(err);
      setError(err.message);
      setLoading(false); // Hide spinner if there's an error
    }
  };

  return (
    <CoverLayout
      color="white"
      image={bgSignIn}
      premotto="SIGN UP TODAY FOR"
      motto="THE CODEGROW AI DASHBOARD"
      cardContent
      minHeight="100vh" // Ensure the cover layout takes up the full screen height
      display="flex"

      justifyContent="center"
      alignItems="center"
    >
      <GradientBorder style={{marginTop: isMobile? '-30%': ''}} borderRadius={borders.borderRadius.form} width="100%"  maxWidth="500px">
        <VuiBox
       
          component="form"
          role="form"
          borderRadius="inherit"
          p="45px"
          onSubmit={handleSignUp} // Attach form submission handler
          sx={({ palette: { secondary } }) => ({
            backgroundColor: secondary.focus,
          })}
        >
          {error && (
            <VuiTypography color="red" textAlign="center" mb="16px">
              {error}
            </VuiTypography>
          )}

          <VuiBox mb={2}>
            <VuiBox mb={1} ml={0.5}>
              <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
                Name
              </VuiTypography>
            </VuiBox>
            <GradientBorder
              width="100%"
              borderRadius={borders.borderRadius.lg}
              padding="1px"
              backgroundImage={radialGradient(
                palette.gradients.borderLight.main,
                palette.gradients.borderLight.state,
                palette.gradients.borderLight.angle
              )}
            >
              <VuiInput
                placeholder="Your full name..."
                value={name}
                onChange={(e) => setName(e.target.value)} // Handle input change
                sx={({ typography: { size } }) => ({
                  fontSize: size.sm,
                })}
              />
            </GradientBorder>
          </VuiBox>

          <VuiBox mb={2}>
            <VuiBox mb={1} ml={0.5}>
              <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
                Email
              </VuiTypography>
            </VuiBox>
            <GradientBorder
              width="100%"
              borderRadius={borders.borderRadius.lg}
              padding="1px"
              backgroundImage={radialGradient(
                palette.gradients.borderLight.main,
                palette.gradients.borderLight.state,
                palette.gradients.borderLight.angle
              )}
            >
              <VuiInput
                type="email"
                placeholder="Your email..."
                value={email}
                onChange={(e) => setEmail(e.target.value)} // Handle input change
                sx={({ typography: { size } }) => ({
                  fontSize: size.sm,
                })}
              />
            </GradientBorder>
          </VuiBox>

          <VuiBox mb={2}>
            <VuiBox mb={1} ml={0.5}>
              <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
                Password
              </VuiTypography>
            </VuiBox>
            <GradientBorder
              width="100%"
              borderRadius={borders.borderRadius.lg}
              padding="1px"
              backgroundImage={radialGradient(
                palette.gradients.borderLight.main,
                palette.gradients.borderLight.state,
                palette.gradients.borderLight.angle
              )}
            >
              <VuiInput
                type="password"
                placeholder="Your password..."
                value={password}
                onChange={(e) => setPassword(e.target.value)} // Handle input change
                sx={({ typography: { size } }) => ({
                  fontSize: size.sm,
                })}
              />
            </GradientBorder>
          </VuiBox>

          <VuiBox display="flex" alignItems="center">
            <VuiSwitch color="info" checked={rememberMe} onChange={handleSetRememberMe} />
            <VuiTypography
              variant="caption"
              color="white"
              fontWeight="medium"
              onClick={handleSetRememberMe}
              sx={{ cursor: "pointer", userSelect: "none" }}
            >
              &nbsp;&nbsp;&nbsp;&nbsp;Remember me
            </VuiTypography>
          </VuiBox>

          <VuiBox mt={4} mb={1}>
            <VuiButton color="info" fullWidth type="submit">
              {loading ? <CircularProgress color="inherit" size={24} /> : "SIGN UP"} {/* Show spinner when loading */}
            </VuiButton>
          </VuiBox>

          <VuiBox mt={3} textAlign="center">
            <VuiTypography variant="button" color="text" fontWeight="regular">
              Already have an account?{" "}
              <VuiTypography
                component={Link}
                to="/authentication/sign-in"
                variant="button"
                color="white"
                fontWeight="medium"
              >
                Sign in
              </VuiTypography>
            </VuiTypography>
          </VuiBox>
        </VuiBox>
      </GradientBorder>
    </CoverLayout>
  );
}

export default SignIn;
