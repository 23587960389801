// Import PropTypes for type checking
import PropTypes from "prop-types";

function FileIcon({ size }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="4" fill="#fc3837" /> {/* Red background */}
      <path
        d="M14 2H6C5.44772 2 5 2.44772 5 3V21C5 21.5523 5.44772 22 6 22H18C18.5523 22 19 21.5523 19 21V8L14 2Z"
        fill="white" // White color for the file icon
      />
      <path
        d="M14 2V8H19"
        stroke="white" // White stroke for the top edge
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

// Setting default values for the props of FileIcon
FileIcon.defaultProps = {
  size: "24px",
};

// Typechecking props for the FileIcon
FileIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default FileIcon;
