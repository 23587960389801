import React, { useEffect, useState } from 'react';
import Card from "@mui/material/Card";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import Invoice from "layouts/billing/components/Invoice"; // Adjust the path as necessary
import { getFunctions, httpsCallable } from "firebase/functions";
import { getAuth } from "firebase/auth";

function Invoices() {
  const [invoices, setInvoices] = useState([]);
  const auth = getAuth(); // Firebase Auth to get the logged-in user's info
  const functions = getFunctions();

  useEffect(() => {
    const fetchInvoices = async () => {
      try {
        const currentUser = auth.currentUser;
        if (!currentUser) {
          console.error('No user is logged in');
          return;
        }

        const getInvoices = httpsCallable(functions, 'getInvoices');
        const result = await getInvoices({ customerId: 'cus_12345' }); // Replace with actual Stripe customer ID

        setInvoices(result.data.invoices); // Store the invoices in state
      } catch (error) {
        console.error('Error fetching invoices:', error);
      }
    };

    fetchInvoices();
  }, [auth, functions]);

  return (
    <Card id="delete-account" sx={{ height: "100%" }}>
      <VuiBox mb="28px" display="flex" justifyContent="space-between" alignItems="center">
        <VuiTypography variant="h6" fontWeight="medium" color="white">
          Invoices
        </VuiTypography>
      </VuiBox>
      <VuiBox>
        <VuiBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {invoices.map((invoice) => (
            <Invoice
              key={invoice.id}
              date={new Date(invoice.created * 1000).toLocaleDateString()} // Convert Unix timestamp
              id={`#${invoice.id}`}
              price={`$${(invoice.amount_paid / 100).toFixed(2)}`} // Convert amount from cents to dollars
            />
          ))}
        </VuiBox>
      </VuiBox>
    </Card>
  );
}

export default Invoices;
