import Card from "@mui/material/Card";
import React, { useState, useEffect } from "react";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import DashboardLayout from "examples/LayoutContainers/TableDashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import VuiButton from "components/VuiButton";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Switch,
  FormControlLabel,
  CircularProgress,
  Button
} from "@mui/material";

import { getFirestore, doc, setDoc, getDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { IoCopy } from "react-icons/io5";
import { jsPDF } from "jspdf"; // Import jsPDF for PDF generation
import { IoArrowDownCircle } from "react-icons/io5";
import { IoCloudDownload } from "react-icons/io5";

import './Projects.css'; // Import your CSS file
import { useMediaQuery } from '@mui/material'; // Import useMediaQuery

import ProjectsTemplatesTable from "./data/ProjectsTemplates";

function Tracker() {
  const [openModal, setOpenModal] = useState(false);
  const [openModal1, setOpenModal1] = useState(false);
  const [liveProjects, setLiveProjects] = useState([]);
  const [projects, setProjects] = useState([]);
  const [savingIndex, setSavingIndex] = useState(null); // Index of the project being saved
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false); // State for delete confirmation dialog
  const [projectToDelete, setProjectToDelete] = useState(null); // State to hold the project to delete
  const [selectedProject, setSelectedProject] = useState(null);

  const [revenue, setRevenue] = useState("");
  const [date, setDate] = useState("");
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [revenues, setRevenues] = useState([]);
  // const [minimizedProjects, setMinimizedProjects] = useState({}); // Track minimized state for each project
  const [minimizedProjects, setMinimizedProjects] = useState({});




  useEffect(() => {
    const fetchLiveProjects = async () => {
      const db = getFirestore();
      const auth = getAuth();
      const userId = auth.currentUser?.uid;
      if (userId) {
        const userDocRef = doc(db, "users", userId);
        const userDocSnapshot = await getDoc(userDocRef);
        if (userDocSnapshot.exists()) {
          const projects = userDocSnapshot.data().liveProjects || [];
          setLiveProjects(projects);

          // Set initial minimized state for each project to true (minimized)
          const initialState = {};
          projects.forEach((_, index) => {
            initialState[index] = true; // Set to true to start minimized
          });
          setMinimizedProjects(initialState); // Update minimizedProjects state
        }
      }
    };
    fetchLiveProjects();
  }, []);

  const fetchProjects = async () => {
    const db = getFirestore();
    const auth = getAuth();
    const user = auth.currentUser;
    if (user) {
      const userDocRef = doc(db, "users", user.uid);
      const userDocSnapshot = await getDoc(userDocRef);
      if (userDocSnapshot.exists()) {
        setProjects(userDocSnapshot.data().projects || []);
      }
    }
  };

  useEffect(() => {
    if (openModal) {
      fetchProjects();
    }
  }, [openModal]);

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleOpen1 = (project) => {
    // Format the description into paragraphs and apply header detection
    const formattedDetails = project.details.split('\n').map((line, index) => {
      const isHeader = line.startsWith('# '); // Detect if the line is a header
      return (
        <VuiTypography
          key={index}
          variant="body1"
          color="white"
          style={{ marginBottom: '16px', fontWeight: isHeader ? 'bold' : 'normal' }} // Set font weight based on header detection
        >
          {isHeader ? line.substring(2) : line} {/* Remove the "# " for header lines */}
        </VuiTypography>
      );
    });

    setSelectedProject({ ...project, formattedDetails });
    setOpenModal1(true);
  };

  const handleClose1 = () => {
    setOpenModal1(false);
    setSelectedProject(null); // Clear selected project

  };

  const toggleMinimizeProject = (index) => {
    setMinimizedProjects(prev => ({
      ...prev,
      [index]: !prev[index] // Toggle the minimized state of the project at index
    }));
  };


  const handleAddProject = (project) => {
    if (liveProjects.some((liveProject) => liveProject.name === project.name)) {
      alert("This project is already a live creation and cannot be added again.");
    } else {
      // Ensure the project being added has the correct structure
      const newProject = {
        ...project,
        revenue: 0, // Set default revenue
        revenueHistory: [] // Initialize as an empty array
      };

      setLiveProjects((prevProjects) => [...prevProjects, newProject]);
      handleClose();
    }
  };


  const handleChange = (event, index) => {
    const { name, type, value, checked } = event.target;
    const updatedLiveProjects = [...liveProjects];

    // Update the specific project's property
    updatedLiveProjects[index][name] = type === "checkbox" ? checked : value;

    setLiveProjects(updatedLiveProjects); // Update the liveProjects state
  };

  const handleSaveProject = async (index) => {
    const db = getFirestore();
    const auth = getAuth();
    const userId = auth.currentUser.uid;

    setSavingIndex(index); // Set the index of the project being saved

    try {
      // Save the updated liveProjects to Firestore
      await setDoc(doc(db, "users", userId), { liveProjects }, { merge: true });
      console.log("Projects saved:", liveProjects);
    } catch (error) {
      console.error("Error saving projects:", error);
    } finally {
      setSavingIndex(null); // Reset saving index after saving
    }
  };

  const handleDeleteProject = async (index) => {
    const db = getFirestore();
    const auth = getAuth();
    const userId = auth.currentUser.uid;

    // Remove the project from the local state
    const updatedLiveProjects = liveProjects.filter((_, i) => i !== index);
    setLiveProjects(updatedLiveProjects);

    // Update Firestore
    try {
      await setDoc(doc(db, "users", userId), { liveProjects: updatedLiveProjects }, { merge: true });
      console.log("Project deleted from Firestore:", liveProjects[index].name);
    } catch (error) {
      console.error("Error deleting project:", error);
    }
  };

  const openDeleteConfirmation = (index) => {
    setProjectToDelete(index);
    setDeleteConfirmationOpen(true);
  };

  const confirmDeleteProject = () => {
    if (projectToDelete !== null) {
      handleDeleteProject(projectToDelete);
    }
    setDeleteConfirmationOpen(false);
    setProjectToDelete(null);
  };


  const [project, setProject] = useState(null);



  useEffect(() => {
    const fetchProjectAndRevenues = async () => {
      const db = getFirestore();
      const auth = getAuth();
      const userId = auth.currentUser?.uid;

      if (userId) {
        const userDocRef = doc(db, "users", userId);
        const userDocSnapshot = await getDoc(userDocRef);

        if (userDocSnapshot.exists()) {
          const userData = userDocSnapshot.data();
          const fetchedRevenues = Array.isArray(userData.revenues) ? userData.revenues : []; // Ensure it's an array
          const fetchedProject = userData.project; // Ensure this is handled appropriately

          // Sort revenues by date (descending)
          fetchedRevenues.sort((a, b) => new Date(b.date) - new Date(a.date));

          setRevenues(fetchedRevenues);
          setProject(fetchedProject); // Set the project data

          // Calculate total revenue
          const total = fetchedRevenues.reduce((acc, rev) => acc + rev.amount, 0);
          setTotalRevenue(total);
        } else {
          console.error("User document does not exist.");
        }
      }
    };

    fetchProjectAndRevenues();
  }, []);


  const handleAddRevenueToProject = async (index) => {
    setLoading(true);

    if (!revenue || !date) {
      alert("Please fill in both fields.");
      setLoading(false);
      return;
    }

    const newRevenue = {
      amount: parseFloat(revenue),
      date: new Date(date).toISOString(),
    };

    try {
      const db = getFirestore();
      const auth = getAuth();
      const userId = auth.currentUser?.uid;

      // Update the specific project's revenue and revenue history
      const updatedLiveProjects = [...liveProjects];
      updatedLiveProjects[index].revenue += newRevenue.amount;  // Update the total revenue for this project
      updatedLiveProjects[index].revenueHistory = [...(updatedLiveProjects[index].revenueHistory || []), newRevenue]; // Add the new revenue to the history

      setLiveProjects(updatedLiveProjects);

      // Save the updated projects to Firestore
      if (userId) {
        await setDoc(doc(db, "users", userId), { liveProjects: updatedLiveProjects }, { merge: true });
        console.log("Revenue added to project:", updatedLiveProjects[index]);
      }

      // Reset input fields
      setRevenue("");
      setDate("");
    } catch (error) {
      console.error("Error adding revenue:", error);
    } finally {
      setLoading(false);
    }
  };


  const handleDeleteRevenueFromProject = async (projectIndex, revIndex) => {
    setLoading(true);

    try {
      const db = getFirestore();
      const auth = getAuth();
      const userId = auth.currentUser?.uid;

      // Check if the project index is valid
      if (projectIndex < 0 || projectIndex >= liveProjects.length) {
        console.error("Invalid project index:", projectIndex);
        alert("Invalid project selected.");
        return;
      }

      // Check if the revenueHistory exists and is an array
      const project = liveProjects[projectIndex];
      if (!project || !Array.isArray(project.revenueHistory)) {
        console.error("Revenue history is undefined or not an array for project:", project);
        alert("No revenue history found for this project.");
        return;
      }

      // Check if the revenue index is valid
      if (revIndex < 0 || revIndex >= project.revenueHistory.length) {
        console.error("Invalid revenue index:", revIndex);
        alert("Invalid revenue entry selected.");
        return;
      }

      // Create a copy of the live projects array
      const updatedLiveProjects = [...liveProjects];

      // Get the revenue amount to be deleted
      const revenueToDelete = updatedLiveProjects[projectIndex].revenueHistory[revIndex].amount;

      // Remove the specified revenue from the history
      updatedLiveProjects[projectIndex].revenueHistory = updatedLiveProjects[projectIndex].revenueHistory.filter((_, index) => index !== revIndex);

      // Update the total revenue for this project
      updatedLiveProjects[projectIndex].revenue -= revenueToDelete;

      // Update the state with the new projects array
      setLiveProjects(updatedLiveProjects);

      // Save the updated projects to Firestore
      if (userId) {
        await setDoc(doc(db, "users", userId), { liveProjects: updatedLiveProjects }, { merge: true });
        console.log("Revenue deleted from project:", updatedLiveProjects[projectIndex]);
      }
    } catch (error) {
      console.error("Error deleting revenue:", error);
    } finally {
      setLoading(false);
    }
  };



  const generatePDF = (project) => {
    const doc = new jsPDF();
    doc.setFont("Helvetica");

    // Function to add text with pagination handling
    const addTextWithPagination = (text, x, y) => {
      const splitText = doc.splitTextToSize(text, doc.internal.pageSize.getWidth() - 20); // Split text to fit the page width
      for (let i = 0; i < splitText.length; i++) {
        if (y + 10 > doc.internal.pageSize.getHeight() - 10) { // Check if y position exceeds page height
          doc.addPage(); // Add a new page if it does
          y = 10; // Reset y position for new page
        }
        doc.text(splitText[i], x, y);
        y += 10; // Move down for the next line
      }
      return y; // Return the updated y position
    };

    let yPosition = 10; // Starting position for content

    // Adding project details to the PDF
    yPosition = addTextWithPagination(`App Name: ${project.name}`, 10, yPosition);
    yPosition = addTextWithPagination(`App Store Released: ${project.releasedToAppStore ? "Yes" : "No"}`, 10, yPosition);

    if (project.releasedToAppStore) {
      yPosition = addTextWithPagination(`Version Number: ${project.versionNumber || "N/A"}`, 10, yPosition);
      yPosition = addTextWithPagination(`Number of Downloads: ${project.downloads || "N/A"}`, 10, yPosition);
      yPosition = addTextWithPagination(`Number of Active Users: ${project.activeUsers || "N/A"}`, 10, yPosition);
      yPosition = addTextWithPagination(`Number of Reviews: ${project.reviews || "N/A"}`, 10, yPosition);
    }

    // Revenue Section
    yPosition = addTextWithPagination(`AdMob Monetized: ${project.adMobMonetized ? "Yes" : "No"}`, 10, yPosition);
    if (project.adMobMonetized) {
      yPosition = addTextWithPagination(`Number of Requests: ${project.requests || "N/A"}`, 10, yPosition);
      yPosition = addTextWithPagination(`CPM: ${project.cpm || "N/A"}`, 10, yPosition);
    }

    // Total Revenue
    const totalRevenue = project.revenueHistory.reduce((acc, rev) => acc + rev.amount, 0).toFixed(2);
    yPosition = addTextWithPagination(`Total Revenue: € ${totalRevenue}`, 10, yPosition);

    // Revenue History
    yPosition = addTextWithPagination('Revenue History:', 10, yPosition);
    project.revenueHistory.sort((a, b) => new Date(b.date) - new Date(a.date)); // Sort by date

    if (project.revenueHistory.length === 0) {
      yPosition = addTextWithPagination('No revenues added yet.', 10, yPosition);
    } else {
      project.revenueHistory.forEach((rev) => {
        yPosition = addTextWithPagination(`Amount: € ${rev.amount.toFixed(2)} - Date: ${new Date(rev.date).toLocaleDateString()}`, 10, yPosition);
      });
    }

    // Flippa Section
    yPosition = addTextWithPagination(`Flippa: ${project.flippa ? "Yes" : "No"}`, 10, yPosition);
    if (project.flippa) {
      yPosition = addTextWithPagination(`Listed on Flippa: ${project.listedOnFlippa ? "Yes" : "No"}`, 10, yPosition);
      if (project.listedOnFlippa) {
        yPosition = addTextWithPagination(`Listing Price: € ${typeof project.listingPrice === 'number' ? project.listingPrice.toFixed(2) : "N/A"}`, 10, yPosition);
      }
    }

    // Links Section
    yPosition = addTextWithPagination(`Github Link: ${project.githublink || "N/A"}`, 10, yPosition);
    yPosition = addTextWithPagination(`App Store Link: ${project.appstorelink || "N/A"}`, 10, yPosition);
    yPosition = addTextWithPagination(`App Store Connect Link: ${project.appstoreconnectlink || "N/A"}`, 10, yPosition);

    // Project Description

    yPosition = addTextWithPagination(project.details || "No description provided.", 10, yPosition);

    // Save the PDF
    doc.save(`${project.name}_Details.pdf`);
  };



  const isMobile = useMediaQuery('(max-width:600px)'); // Adjust breakpoint as needed


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        <VuiBox mb={3}>
          <Card>
            <VuiBox display="flex" justifyContent="space-between" alignItems="center" mb="22px">
              <VuiTypography variant="lg" color="white">Creation Tracker</VuiTypography>
              <VuiButton variant="contained" color="primary" onClick={handleOpen}>Add Project</VuiButton>
            </VuiBox>

            <VuiBox>
              {liveProjects.map((project, index) => (
                <Card key={index} className="project-section" style={{ marginBottom: '20px' }}>

                  {/* header */}
                  <VuiBox display="flex" flexDirection={isMobile ? "column" : "row"} alignItems="start" justifyContent="space-between" gap="50px">
                    {/* First element with project name */}
                    <VuiTypography variant="h4" color="white" style={{ flex: 1 }}>
                      {project.name} {/* Display the app name here */}
                    </VuiTypography>

                    <VuiBox display="flex" width={isMobile? "100%": "35%"} flexDirection={isMobile ? "row" : "row"} alignItems="start" justifyContent="space-evenly" gap="0px">
                      <VuiButton
                        style={{ backgroundColor: 'blue', padding: 0, }}
                        onClick={() => handleOpen1(project)} // Pass the project to open modal
                      >
                        <IoCopy style={{ fontSize: '70px' }} /> {/* Set fontSize for larger icon */}
                      </VuiButton>

                      <VuiButton
                        style={{ backgroundColor: 'blue', padding: 0 }}
                        onClick={() => generatePDF(project)}
                      >
                        <IoCloudDownload style={{ fontSize: '90px' }} /> {/* Set fontSize for larger icon */}
                      </VuiButton>
                      <Button
                      onClick={() => toggleMinimizeProject(index)}
                      variant="contained"
                      style={{ backgroundColor: 'blue', color: 'white' }}
                    >
                      {minimizedProjects[index] ? 'View' : 'Hide'} Details
                    </Button>
                    </VuiBox>
                    {/* View/Hide button */}
                  




                  </VuiBox>

                  {/* header */}


                  {!minimizedProjects[index] && (
                    <VuiBox mt={2}>

                      <VuiBox display="flex" flexDirection={isMobile? "column" : "row"} justifyContent="space-between" p={2}>
                        {/* Left Section: App Store Released */}
                        <VuiBox flex={1} textAlign="center">
                          <FormControlLabel
                            control={<Switch checked={project.releasedToAppStore} onChange={(e) => handleChange(e, index)} name="releasedToAppStore" />}
                            label="App Store Released"
                          />
                          <br />
                          {project.releasedToAppStore && (
                            <>
                              <VuiTypography variant="h6" color="white" style={{ marginTop: '10px' }} >
                                Version Number
                              </VuiTypography>
                              <TextField
                                name="versionNumber"
                                onChange={(e) => handleChange(e, index)}
                                value={project.versionNumber || ''}
                                margin="normal"

                              />
                              <VuiTypography variant="h6" color="white" style={{ marginTop: '10px' }} >
                                Number of Downloads
                              </VuiTypography>
                              <TextField

                                name="downloads"
                                onChange={(e) => handleChange(e, index)}
                                value={project.downloads || ''}
                                margin="normal"
                              // fullWidth
                              />
                              <VuiTypography variant="h6" color="white" style={{ marginTop: '10px' }} >
                                Number of Active Users
                              </VuiTypography>
                              <TextField

                                name="activeUsers"
                                onChange={(e) => handleChange(e, index)}
                                value={project.activeUsers || ''}
                                margin="normal"

                              />
                              <VuiTypography variant="h6" color="white" style={{ marginTop: '10px' }} >
                                Number of Reviews
                              </VuiTypography>
                              <TextField
                                placeholder="Number of Reviews"
                                name="reviews"
                                onChange={(e) => handleChange(e, index)}
                                value={project.reviews || ''}
                                margin="normal"

                              />
                            </>
                          )}
                        </VuiBox>

                        {/* Middle Section: AdMob Monetized */}
                        <VuiBox flex={1} textAlign="center">
                          <FormControlLabel
                            control={<Switch checked={project.adMobMonetized} onChange={(e) => handleChange(e, index)} name="adMobMonetized" />}
                            label="AdMob Monetized"
                          />
                          <br />

                          {project.adMobMonetized && (
                            <>
                              <VuiTypography variant="h6" color="white" style={{ marginTop: '10px' }} >
                                Number of Requests
                              </VuiTypography>
                              <TextField

                                name="requests"
                                onChange={(e) => handleChange(e, index)}
                                value={project.requests || ''}
                                margin="normal"

                              />
                              <VuiTypography variant="h6" color="white" style={{ marginTop: '10px' }} >
                                CPM
                              </VuiTypography>
                              <TextField

                                name="cpm"
                                onChange={(e) => handleChange(e, index)}
                                value={project.cpm || ''}
                                margin="normal"

                              />
                              {/* <TextField
                            label="Revenue"
                            name="revenue"
                            onChange={(e) => handleChange(e, index)}
                            value={project.revenue || ''}
                            margin="normal"
                            fullWidth
                          /> */}
                            </>
                          )}
                        </VuiBox>

                        {/* Right Section: Flippa */}
                        <VuiBox flex={1} textAlign="center">
                          <FormControlLabel
                            control={<Switch checked={project.flippa} onChange={(e) => handleChange(e, index)} name="flippa" />}
                            label="Flippa"
                          />
                          <br />

                          {project.flippa && (
                            <>
                              <FormControlLabel
                                control={<Switch checked={project.listedOnFlippa} onChange={(e) => handleChange(e, index)} name="listedOnFlippa" />}
                                label="Listed on Flippa"
                              />


                              {project.listedOnFlippa && (
                                <><VuiTypography variant="h6" color="white" style={{ marginTop: '10px' }}>
                                  Listing Price
                                </VuiTypography><TextField
                                    name="listingPrice"
                                    onChange={(e) => handleChange(e, index)}
                                    value={project.listingPrice || ''}
                                    margin="normal" /></>
                              )}
                            </>
                          )}
                        </VuiBox>
                      </VuiBox>
                      <br />
                      <VuiBox display="flex" flexDirection={isMobile? "column" : "row"} alignItems="center" justifyContent="space-evenly">

                        <VuiBox display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                          {/* First element with project name */}
                          <VuiTypography variant="h6" color="white" style={{}}>
                            Github Link
                          </VuiTypography>
                          <TextField
                            placeholder="Github Link"
                            name="githublink"
                            onChange={(e) => handleChange(e, index)}
                            value={project.githublink || ''}
                            margin="normal"
                          />
                        </VuiBox>


                        <VuiBox display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                          {/* First element with project name */}
                          <VuiTypography variant="h6" color="white" style={{}}>
                            App Store Link
                          </VuiTypography>
                          <TextField
                            placeholder="App Store Link"
                            name="appstorelink"
                            onChange={(e) => handleChange(e, index)}
                            value={project.appstorelink || ''}
                            margin="normal"
                          />
                        </VuiBox>

                        <VuiBox display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                          {/* First element with project name */}
                          <VuiTypography variant="h6" color="white" style={{}}>
                            App Store Connect Link
                          </VuiTypography>
                          <TextField
                            placeholder="App Store Connect Link"
                            name="appstoreconnectlink"
                            onChange={(e) => handleChange(e, index)}
                            value={project.appstoreconnectlink || ''}
                            margin="normal"
                          />
                        </VuiBox>

                      </VuiBox>

                      <VuiBox mb={3}></VuiBox>
                      {/* Save and Delete Buttons */}
                      <VuiBox display="flex" justifyContent={isMobile? "space-evenly" : "space-between"} alignItems="center" p={2}>
                        <VuiButton
                          variant="contained"
                          color="primary"
                          onClick={() => handleSaveProject(index)}
                          sx={{ color: savingIndex === index ? 'white' : 'white' }} // Set text color to white when saving
                        >
                          {savingIndex === index ? <CircularProgress size={24} color="inherit" /> : "Save"}
                        </VuiButton>

                        <VuiButton variant="contained" color="error" onClick={() => openDeleteConfirmation(index)}>
                          Delete
                        </VuiButton>


                      </VuiBox>


                      <br />
                      <VuiBox display="flex" flexDirection="row" alignItems="start" justifyContent="space-between" gap="50px" >
                        {/* First element with project name */}
                        <VuiTypography variant="h4" color="white" style={{ flex: 1, }}>
                          Revenue Tracker
                        </VuiTypography>
                      </VuiBox>
                      <VuiBox mb={3}>
                        <Card>


                          {/* Add Revenue Section */}
                          <VuiBox>
                            <TextField
                              placeholder="Revenue Amount"
                              name="revenue"
                              onChange={(e) => setRevenue(e.target.value)}
                              value={revenue}
                              margin="normal"
                              fullWidth
                            />
                            <TextField
                              placeholder="Enter a date"
                              name="date"
                              type="date"
                              onLoad={(e) => setDate({ date })}
                              onChange={(e) => setDate(e.target.value)}
                              value={date}
                              margin="normal"
                              fullWidth
                            />
                            <VuiButton style={{width: isMobile? '100%': '' , marginTop:isMobile? '10px' : ''}}variant="contained" color="primary" onClick={() => handleAddRevenueToProject(index)}>
                              Add Revenue
                            </VuiButton>
                          </VuiBox>

                          {/* Display Total Revenue */}
                          {/* Calculate Total Revenue */}

                          <VuiBox mt={4}>
                            <VuiTypography variant="h5" color="white">
                              Total Revenue: €{project.revenueHistory.reduce((acc, rev) => acc + rev.amount, 0).toFixed(2)}
                            </VuiTypography>
                          </VuiBox>


                          <VuiBox mt={4}>
                            <VuiTypography variant="h6" color="white">Revenue History:</VuiTypography>
                            {project.revenueHistory.length === 0 ? (
                              <VuiTypography variant="body2" color="white">
                                No revenues added yet.
                              </VuiTypography>
                            ) : (
                              project.revenueHistory
                                .sort((a, b) => new Date(b.date) - new Date(a.date)) // Sort by date
                                .map((rev, revIndex) => (
                                  <VuiBox key={revIndex} display="flex" justifyContent="space-between" flexDirection={isMobile? 'column' : 'row'} mt={2} >
                                    <div style={{ display: "flex",  justifyContent: "start", width: "40%", alignSelf: "start" }}>
                                      <VuiTypography color="white" style={{ marginRight: "50px" }}>
                                        Amount: €{rev.amount.toFixed(2)}
                                      </VuiTypography>
                                      <VuiTypography color="white">
                                        Date: {new Date(rev.date).toLocaleDateString()}
                                      </VuiTypography>
                                    </div>

                                    <VuiBox display="flex" justifyContent="space-between" >
                                      <VuiButton
                                        variant="outlined"
                                        color="error"
                                        onClick={() => handleDeleteRevenueFromProject(index, revIndex)} // Call the delete function
                                        style={{width: isMobile? '100%': '' , marginTop:isMobile? '10px' : ''}}
                                      >
                                        Delete
                                      </VuiButton>
                                    </VuiBox>
                                  </VuiBox>
                                ))
                            )}
                          </VuiBox>



                        </Card>
                      </VuiBox>




                    </VuiBox>
                  )}



                </Card>
              ))}
            </VuiBox>
          </Card>
        </VuiBox>
      </VuiBox>
      <Footer />

      {/* Modal for showing project details */}
      <Dialog  open={openModal1} maxWidth="lg" onClose={handleClose1}>
        <DialogTitle  style={{ backgroundColor: '#000000', color: 'white' ,  }}>{selectedProject?.name}</DialogTitle>
        <DialogContent class="custom-scrollbar" style={{ backgroundColor: '#000000', color: 'white', padding:'10px' }}>
          {selectedProject?.formattedDetails}
        </DialogContent>
        <DialogActions style={{ backgroundColor:'#151243'}}>
          <VuiButton style={{ backgroundColor: 'blue', color: 'white' }} onClick={handleClose1}>Close</VuiButton>
        </DialogActions>
      </Dialog>

      <Dialog open={openModal} onClose={handleClose} maxWidth="lg" fullWidth>
        <DialogTitle style={{ color: 'white', backgroundColor: 'black' }}>
          Select a Project to Add
        </DialogTitle>
        <DialogContent style={{ color: 'white', backgroundColor: 'black' }}>
          {projects.length > 0 ? (
            <div style={{
              display: 'flex',
              flexWrap: 'wrap', // Allow buttons to wrap onto the next line
              justifyContent:  isMobile? "center" :'flex-start', // Aligns items to the start of the container
            }}>
              {projects.map((project, index) => (
                <VuiButton
                  key={index}
                  variant="outlined"
                  color="primary"
                  onClick={() => handleAddProject(project)}
                  style={{
                    margin: '10px', // Add margin for spacing between buttons
                    flex:  '0 0 calc(20% - 20px)', // Sets each button to occupy 20% of the width minus margins (5 buttons per line)
                    maxWidth:  isMobile? "100%" :'calc(20% - 20px)', // Ensures buttons don't exceed the width for 5 buttons
                    minWidth:  isMobile? "100%" :'150px', // Optional: sets a minimum width
                  }}
                >
                  {project.name}
                </VuiButton>
              ))}
            </div>
          ) : (
            <VuiTypography variant="body1" color="white">No projects available</VuiTypography>
          )}
        </DialogContent>
        <DialogActions>
          <VuiButton onClick={handleClose} color="secondary">Close</VuiButton>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog open={deleteConfirmationOpen} onClose={() => setDeleteConfirmationOpen(false)}>
        <DialogTitle style={{ backgroundColor: 'black', color: 'white', border: '1px solid black' }}>Confirm Delete</DialogTitle>
        <DialogContent style={{ backgroundColor: 'black', color: 'white' }}>
          <VuiTypography variant="body1" color="white">
            Are you sure you want to delete this project? This action cannot be undone.
          </VuiTypography>
        </DialogContent>
        <DialogActions style={{ backgroundColor: 'black', color: 'white' }}>
          <VuiButton color="error" onClick={confirmDeleteProject}>
            Delete
          </VuiButton>
          <VuiButton style={{ backgroundColor: '#4316ff', color: 'white' }} onClick={() => setDeleteConfirmationOpen(false)}>Cancel</VuiButton>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}

export default Tracker;
