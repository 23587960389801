import Card from "@mui/material/Card";
import React, { useState } from "react"; // Import useState
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import DashboardLayout from "examples/LayoutContainers/TableDashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import { Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import VuiButton from "components/VuiButton";

import ProjectsTemplatesTable from "./data/ProjectsTemplates";
import './Projects.css'; // Import your CSS file

function Projects() {
  const [openModal, setOpenModal] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null); // State to hold the selected project

  // Function to handle opening the modal
  const handleOpen = (project) => {
    const formattedDetails = project.details.split('\n').map((line, index) => {
      const isHeader = line.startsWith('# '); // Detect if the line is a header
      return (
        <VuiTypography
          key={index}
          variant="body1"
          color="white"
          style={{ marginBottom: '16px', fontWeight: isHeader ? 'bold' : 'normal' }} // Set font weight based on header detection
        >
          {isHeader ? line.substring(2) : line} {/* Remove the "# " for header lines */}
        </VuiTypography>
      );
    });
    
    setSelectedProject({ ...project, formattedDetails }); // Set the selected project with formatted details
    setOpenModal(true); // Open the modal
  };

  // Function to handle closing the modal
  const handleClose = () => {
    setOpenModal(false); // Close the modal
    setSelectedProject(null); // Clear selected project
  };  
  
  const { columns, rows } = ProjectsTemplatesTable(handleOpen);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        <VuiBox mb={3}>
          <Card>
            <VuiBox display="flex" justifyContent="space-between" alignItems="center" mb="22px">
              <VuiTypography variant="lg" color="white">
                Saved Projects
              </VuiTypography>
            </VuiBox>
            <VuiBox
              sx={{
                "& th": {
                  borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                    `${borderWidth[1]} solid ${grey[700]}`,
                },
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                      `${borderWidth[1]} solid ${grey[700]}`,
                  },
                },
              }}
            >
              <Table
                columns={columns}
                rows={rows} // Keep rows as is, it now contains proper Action buttons
              />

              {/* Modal for Project Details */}
              <Dialog open={openModal} onClose={handleClose} maxWidth="lg" fullWidth>
                <DialogTitle style={{ color: 'white', backgroundColor: 'black' }}>
                  {selectedProject?.name || "Project Details"}
                </DialogTitle>
                <DialogContent className="custom-scrollbar" style={{ color: 'white', backgroundColor: 'black' }}>
                  <VuiBox>
                    {selectedProject ? selectedProject.formattedDetails : "Loading..."}
                  </VuiBox>
                </DialogContent>
                <DialogActions sx={{ backgroundColor: '#151243' }}>
                  <VuiButton onClick={handleClose} color="primary">Close</VuiButton>
                </DialogActions>
              </Dialog>
            </VuiBox>
          </Card>
        </VuiBox>
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Projects;
