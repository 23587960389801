import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "context/AuthContext"; // Make sure to import useAuth

const Logout = () => {
  const navigate = useNavigate(); // Use useNavigate instead of useHistory
  const { logout } = useAuth(); // Get the logout function from AuthContext

  useEffect(() => {
    const performLogout = async () => {
      try {
        await logout(); // Call the logout function to sign out from Firebase
        localStorage.removeItem('token'); // Adjust this based on your authentication method

        // Redirect to Sign In page after logout
        navigate('/authentication/sign-in'); // Use navigate instead of history.push
      } catch (error) {
        console.error("Error during logout:", error);
      }
    };

    performLogout();
  }, [logout, navigate]); // Include logout in the dependency array

  return null; // No need to render anything on this page
};

export default Logout;
