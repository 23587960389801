import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { BsCheckCircleFill } from "react-icons/bs";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

// Vision UI Dashboard Material-UI example components
import Table from "examples/Tables/Table";
import { Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import VuiButton from "components/VuiButton";
import { useNavigate } from "react-router-dom"; // For navigation

// Data
import ProjectsTemplatesTable from "layouts/dashboard/components/Projects/data";
import './Projects.css'; // Import your CSS file
import { TiThMenu } from "react-icons/ti";

function Projects() {
  const [menu, setMenu] = useState(null);
  const navigate = useNavigate(); // React Router's navigation hook

  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);

  // Handle click for the "Tap to start working" button
  const handleStartWorking = () => {
    navigate("/ai-app-tool"); // Navigate to the /ai-app-tool page
  };

  const handleView = () => {
    navigate("/projects"); // Navigate to the /projects page
  };

  const handleLearn = () => {
    navigate("/Full-Course"); // Navigate to the /Full-Course page
  };

  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <MenuItem onClick={handleStartWorking}>Create new project</MenuItem>
      <MenuItem onClick={handleView}>View projects</MenuItem>
      <MenuItem onClick={handleLearn}>Learn</MenuItem>
    </Menu>
  );

  const [openModal, setOpenModal] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null); // State to hold the selected project

  // Function to handle opening the modal
  const handleOpen = (project) => {
    const formattedDetails = project.details.split('\n').map((line, index) => {
      const isHeader = line.startsWith('# '); // Detect if the line is a header
      return (
        <VuiTypography
          key={index}
          variant="body1"
          color="white"
          style={{ marginBottom: '16px', fontWeight: isHeader ? 'bold' : 'normal' }} // Set font weight based on header detection
        >
          {isHeader ? line.substring(2) : line} {/* Remove the "# " for header lines */}
        </VuiTypography>
      );
    });

    setSelectedProject({ ...project, formattedDetails }); // Set the selected project with formatted details
    setOpenModal(true); // Open the modal
  };

  // Function to handle closing the modal
  const handleClose = () => {
    setOpenModal(false); // Close the modal
    setSelectedProject(null); // Clear selected project
  };

  const { columns, rows } = ProjectsTemplatesTable(handleOpen);

  // Count the number of projects
  const projectCount = rows.length;

  return (
    <Card
      sx={{
        minHeight:'100%',
        maxHeight: "87.5% !important",
      }}
    >
      <VuiBox display="flex" justifyContent="space-between" alignItems="center" mb="32px">
        <VuiBox mb="auto">
          <VuiTypography color="white" variant="lg" mb="6px" gutterBottom>
            Projects
          </VuiTypography>
          <VuiBox display="flex" alignItems="center" lineHeight={0}>
            <BsCheckCircleFill color="green" size="15px" />
            <VuiTypography variant="button" fontWeight="regular" color="text" ml="5px">
              &nbsp;<strong>{projectCount} projects</strong> started
            </VuiTypography>
          </VuiBox>
        </VuiBox>
        <VuiBox color="text" px={2}>
          <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small" onClick={openMenu}>
          <TiThMenu style={{marginBottom:'10px'}} />

          </Icon>
        </VuiBox>
        {renderMenu}
      </VuiBox>

      <VuiBox
        className="custom-scrollbar"
        sx={{
          "& th": {
            borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
              `${borderWidth[1]} solid ${grey[700]}`,
          },
          "& .MuiTableRow-root:not(:last-child)": {
            "& td": {
              borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                `${borderWidth[1]} solid ${grey[700]}`,
            },
          },
          maxHeight: "80%", // Set max height for scrolling
          overflowY: "auto", // Enable vertical scrolling
        }}
      >
        <Table 
          columns={columns}
          rows={rows.slice(0, 7)} // Limit to the first 7 entries
        />

        <Dialog open={openModal} onClose={handleClose} maxWidth="lg" fullWidth>
          <DialogTitle style={{ color: 'white', backgroundColor: 'black' }}>
            {selectedProject?.name || "Project Details"}
          </DialogTitle>
          <DialogContent className="custom-scrollbar" style={{ color: 'white', backgroundColor: 'black' }}>
            <VuiBox>
              {selectedProject ? selectedProject.formattedDetails : "Loading..."}
            </VuiBox>
          </DialogContent>
          <DialogActions sx={{ backgroundColor: '#151243' }}>
            <VuiButton onClick={handleClose} color="primary">Close</VuiButton>
          </DialogActions>
        </Dialog>
      </VuiBox>
    </Card>
  );
}

export default Projects;
