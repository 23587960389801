import React, { useState, useEffect } from 'react';
import { getFirestore, doc, getDocs, setDoc, deleteDoc, collection } from "firebase/firestore";
import { Card, TextField, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import { getAuth } from "firebase/auth";
import './Projects.css'; // Import your CSS file
import { IoCopy } from "react-icons/io5";
import { IoCreate } from "react-icons/io5";
import { IoTrashSharp } from "react-icons/io5";
import { useMediaQuery } from '@mui/material'; // Import useMediaQuery

const AppIdeaNotes = () => {
  const [ideas, setIdeas] = useState([]);
  const [newIdea, setNewIdea] = useState('');
  const [editId, setEditId] = useState(null);
  const [editValue, setEditValue] = useState('');
  const db = getFirestore();
  const auth = getAuth();
  const user = auth.currentUser;
  const [copiedIndex, setCopiedIndex] = useState(null);
  const isMobile = useMediaQuery('(max-width:600px)'); // Adjust breakpoint as needed


  if (!user) {
    console.error("User is not authenticated.");
    return null;
  }

  const userId = user.uid;

  // Load ideas from Firestore on component mount
  useEffect(() => {
    const fetchIdeas = async () => {
      const ideasCollection = collection(db, `users/${userId}/ideas`);
      const ideaSnapshot = await getDocs(ideasCollection);
      const ideaList = ideaSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setIdeas(ideaList);
    };

    fetchIdeas();
  }, [userId]);

  // Handle adding a new idea
  const addIdea = async () => {
    if (newIdea.trim()) {
      const newIdeaData = { idea: newIdea };

      const docRef = doc(db, `users/${userId}/ideas`, new Date().getTime().toString());
      await setDoc(docRef, newIdeaData);
      setIdeas([...ideas, { ...newIdeaData, id: docRef.id }]);
      setNewIdea('');
    }
  };

  // Handle editing an existing idea
  const editIdea = async () => {
    if (editValue.trim()) {
      const updatedIdea = { idea: editValue };
      await setDoc(doc(db, `users/${userId}/ideas`, editId), updatedIdea);
      setIdeas(ideas.map(i => (i.id === editId ? { ...i, ...updatedIdea } : i)));
      setEditId(null);
      setEditValue('');
    }
  };

  // Handle deleting an idea
  const deleteIdea = async (id) => {
    await deleteDoc(doc(db, `users/${userId}/ideas`, id));
    setIdeas(ideas.filter(i => i.id !== id));
  };

  // Refresh ideas
  const refreshIdeas = async () => {
    const ideasCollection = collection(db, `users/${userId}/ideas`);
    const ideaSnapshot = await getDocs(ideasCollection);
    const ideaList = ideaSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setIdeas(ideaList);
  };

  const handleCopy = (idea, index) => {
    navigator.clipboard.writeText(idea);
    setCopiedIndex(index);
    setTimeout(() => setCopiedIndex(null), 5000); // Reset after 5 seconds
  };


  return (
    <Card style={{
      minHeight: '450px', maxHeight: '450px',
    }}>
      <VuiBox p={2} >
        <VuiTypography style={{ color: 'white' }} variant="h5">App Idea Quick Notes</VuiTypography>
        <VuiBox mt={2} mb={2} display="flex" alignItems="center">
          <TextField
            placeholder="New Idea"
            value={newIdea}
            onChange={(e) => setNewIdea(e.target.value)}
            variant="outlined"
            fullWidth
            InputProps={{
              style: { color: 'white' },
            }}
            style={{ backgroundColor: '#333', color: 'white' }}
          />
          <Button
            onClick={addIdea}
            variant="contained"
            color="primary"
            style={{ marginLeft: '10px', fontSize: '18px', padding: '0px', color: 'white' }}
          >
            +
          </Button>
          <Button
            onClick={refreshIdeas}
            variant="outlined"
            style={{ marginLeft: '10px', color: 'white', borderColor: 'white' }}
          >
            Refresh
          </Button>
        </VuiBox>


        <TableContainer
          className="custom-scrollbar"
          component={Paper}
          style={{ backgroundColor: 'transparent', maxHeight: '300px', overflowY: 'auto' }}
        >
          <Table stickyHeader aria-label="ideas table">
            <tbody>
              {ideas.map(idea => (
                <tr key={idea.id}>
                  <td component="th" scope="row" style={{ color: 'white', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {editId === idea.id ? (
                      <TextField
                        value={editValue}
                        onChange={(e) => setEditValue(e.target.value)}
                        variant="outlined"
                        size="small"
                        fullWidth
                        InputProps={{
                          style: { color: 'white' },
                        }}
                        style={{ backgroundColor: '#444' }}
                      />
                    ) : (
                      <VuiTypography style={{ color: 'white', flexGrow: 1, fontSize: isMobile ? '15px' : '20px' }}>{idea.idea}</VuiTypography>
                    )}

                    <div style={{ display: 'flex', marginLeft: '10px' }}>
                      <Button
                        onClick={() => handleCopy(idea.idea, idea.id)} // Pass the index
                        color="secondary"
                        style={{
                          marginRight: isMobile ? '-10px' : '10px',
                          color: isMobile ? (copiedIndex === idea.id ? 'green' : 'blue') : 'white',
                          backgroundColor: isMobile ? 'transparent' : (copiedIndex === idea.id ? 'green' : 'blue'), // Change color based on copiedIndex
                          borderColor: 'white',
                        }}
                      >
                        <IoCopy />
                      </Button>

                      {editId === idea.id ? (
                        <Button
                          onClick={editIdea}
                          variant="contained"
                          color="secondary"
                          style={{ marginRight: '5px' }}
                        >
                          Save
                        </Button>
                      ) : (
                        <>
                          <Button
                            onClick={() => { setEditId(idea.id); setEditValue(idea.idea); }}
                            color="primary"
                            style={{
                              marginRight: isMobile? '-10px' : '10px',
                              color: 'white',
                              backgroundColor: isMobile? 'transparent' : 'blue',
                              borderColor: 'white'
                            }}
                          >
                            <IoCreate />
                          </Button>
                          <Button
                            onClick={() => deleteIdea(idea.id)}
                            style={{
                               marginRight: isMobile? '0px' : '10px', 
                               color: isMobile? 'red' : 'white', 
                               backgroundColor: isMobile? 'transparent' : 'red', 
                               borderColor: 'white' }}
                          >
                            <IoTrashSharp />
                          </Button>

                        </>
                      )}

                    </div>

                  </td>
                  <hr style={{ border: '1px solid #313eac', marginTop: '10px', marginBottom: '10px' }} />

                </tr>
              ))}
            </tbody>
          </Table>
        </TableContainer>

      </VuiBox>
    </Card>
  );
};

export default AppIdeaNotes;
