/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react"; 
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";

// Initialize Firestore (Assuming Firebase is already initialized in your project)
const db = getFirestore();
const auth = getAuth();

function ProjectsTemplatesTable(handleOpen) {
  const [projects, setProjects] = useState([]);

  // Fetch projects from Firestore when the component mounts
  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const user = auth.currentUser; // Get the current logged-in user
        if (user) {
          const userDocRef = doc(db, "users", user.uid);
          const userDocSnapshot = await getDoc(userDocRef);
          
          if (userDocSnapshot.exists()) {
            const userData = userDocSnapshot.data();
            const projectsData = userData.projects || [];
            setProjects(projectsData); // Set the projects in the state
          } else {
            console.error("User document not found.");
          }
        } else {
          console.error("User not logged in.");
        }
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };

    fetchProjects();
  }, []);

  // Create rows from the Firestore data
  const rows = projects.map((project, index) => ({
    id: index, // Add an ID for unique identification
    Title: ( // Ensure "Title" matches with the column name
      <VuiTypography
        variant="button"
        color="white"
        fontWeight="medium"
        style={{ cursor: "pointer", textDecoration: "none" }}
      >
        {project.name || "Untitled Project"}
      </VuiTypography>
    ),
    Action: (
      <VuiButton
        onClick={() => handleOpen(project)} // Pass the project object here
        color="primary"
        size="small"
      >
        View
      </VuiButton>
    ),
  }));

  return {
    columns: [
      { name: "Title", align: "left" },
      { name: "Action", align: "center" },
    ],
    rows, // Return the rows created from the flat JSON data
  };
}

export default ProjectsTemplatesTable;
