import React, { useEffect, useState } from "react"; 
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";
import { getFirestore, doc, getDoc, updateDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";

// Initialize Firestore (Assuming Firebase is already initialized in your project)
const db = getFirestore();
const auth = getAuth();

function ProjectsTemplatesTable(handleOpen) {
  const [projects, setProjects] = useState([]);

  // Fetch projects from Firestore when the component mounts
  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const user = auth.currentUser; // Get the current logged-in user
        if (user) {
          const userDocRef = doc(db, "users", user.uid);
          const userDocSnapshot = await getDoc(userDocRef);
          
          if (userDocSnapshot.exists()) {
            const userData = userDocSnapshot.data();
            const projectsData = userData.projects || [];
            setProjects(projectsData); // Set the projects in the state
          } else {
            console.error("User document not found.");
          }
        } else {
          console.error("User not logged in.");
        }
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };

    fetchProjects();
  }, []);
  // Function to delete a project from Firestore
  const handleDelete = async (projectIndex) => {
    try {
      const user = auth.currentUser; // Get the current logged-in user
      if (user) {
        const userDocRef = doc(db, "users", user.uid);
        const userDocSnapshot = await getDoc(userDocRef);
        
        if (userDocSnapshot.exists()) {
          const userData = userDocSnapshot.data();
          const updatedProjects = userData.projects.filter((_, index) => index !== projectIndex);
          
          // Update the Firestore document
          await updateDoc(userDocRef, { projects: updatedProjects });
          
          // Update the state with the new project list
          setProjects(updatedProjects);
        } else {
          console.error("User document not found.");
        }
      } else {
        console.error("User not logged in.");
      }
    } catch (error) {
      console.error("Error deleting project:", error);
    }
  };
  // Create rows from the Firestore data
  const rows = projects.map((project, index) => ({
    id: index, // Add an ID for unique identification
    Title: ( // Ensure "Title" matches with the column name
      <VuiTypography
        variant="button"
        color="white"
        fontWeight="medium"
        style={{ cursor: "pointer", textDecoration: "none" }}
      >
        {project.name || "Untitled Project"}
      </VuiTypography>
    ),
    Action: (
      <>
        <VuiButton
          onClick={() => handleOpen(project)} // Pass the project object here
          color="primary"
          size="small"
          style={{ marginRight: '8px' }} // Add some spacing between buttons
        >
          View
        </VuiButton>
        <VuiButton
          onClick={() => handleDelete(index)} // Pass the project index here
          style={{ color: 'red' }}
          size="small"
        >
          Delete
        </VuiButton>
      </>
    ),
  }));
  return {
    columns: [
      { name: "Title", align: "left" },
      { name: "Action", align: "center" },
    ],
    rows, // Return the rows created from the flat JSON data
  };
}

export default ProjectsTemplatesTable;


