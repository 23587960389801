import React, { useState } from 'react';
import axios from 'axios';
import { Card, CircularProgress, Button, Dialog, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import VuiBox from 'components/VuiBox'; // Replace with actual import paths
import VuiTypography from 'components/VuiTypography'; // Replace with actual import paths
import { getAuth } from "firebase/auth";
import { getFirestore, doc, setDoc } from "firebase/firestore";
import { useMediaQuery } from '@mui/material'; // Import useMediaQuery
import { FaCopy } from "react-icons/fa";
import { IoSaveSharp } from "react-icons/io5";

const AIAppToolPage = () => {
  const [projectSaved, setProjectSaved] = useState([]); // Start with an empty array
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [fullResponse, setFullResponse] = useState('');
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ideas, setIdeas] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [copiedIndex, setCopiedIndex] = useState(null);
  const apiKey = process.env.REACT_APP_API_KEY;
  const isMobile = useMediaQuery('(max-width:600px)'); // Adjust breakpoint as needed

  const logError = (error) => {
    if (error.response) {
      console.error("Error response data:", error.response.data);
      console.error("Error response status:", error.response.status);
      console.error("Error response headers:", error.response.headers);
    } else if (error.request) {
      console.error("No response received:", error.request);
    } else {
      console.error("Error setting up request:", error.message);
    }
  };

  const handleCreateProject = async () => {
    setProjectSaved(false);

    if (fullResponse && unsavedChanges) {
      setConfirmDialogOpen(true);
      return;
    }

    setFullResponse('');
    setLoading(true);

    try {
      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: 'gpt-3.5-turbo',
          messages: [
            {
              role: 'user',
              content:
                "Generate 10 simple app/game ideas with descriptions, make it a mixture of games and apps. Format it so my code can read the name and description using `const [name, ...description] = idea.split(': ');`",
            },
          ],
        },
        {
          headers: {
            Authorization: `Bearer ${apiKey}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.data.choices && response.data.choices.length > 0) {
        const ideasArray = response.data.choices[0].message.content
          .split('\n')
          .filter((line) => line.trim() !== "") // Filter out empty lines
          .map((idea, index) => {
            // Remove the leading number and period using regex
            const cleanedIdea = idea.replace(/^\d+\.\s*/, '');
            const [name, ...description] = cleanedIdea.split(': ');
            return {
              name: name.trim(),
              description: description.join(': ').trim(),
              index,
            };
          });

        setIdeas(ideasArray);
        setProjectSaved(Array(ideasArray.length).fill(false)); // Set saved states based on the length of ideas
        setShowTable(true);
      }
    } catch (error) {
      logError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDiscard = async () => {
    setLoading(true);

    try {
      setFullResponse('');
      setIdeas([]);
      setShowTable(false);
      await handleCreateProject();
    } catch (error) {
      logError(error);
    } finally {
      setLoading(false);
      setConfirmDialogOpen(false);
    }
  };

  const resetState = () => {
    setFullResponse('');
    setIdeas([]);
    setShowTable(false);
    setUnsavedChanges(false);
    setProjectSaved(Array(ideas.length).fill(false)); // Reset saved states
  };


  const handleCopyIdea = (ideaName, index) => {
    navigator.clipboard.writeText(ideaName);
    setCopiedIndex(index);
    setTimeout(() => setCopiedIndex(null), 5000); // Reset after 5 seconds
  };

  const db = getFirestore();
  const auth = getAuth();
  const user = auth.currentUser;

  if (!user) {
    console.error("User is not authenticated.");
    return null;
  }

  const userId = user.uid;

  const handleSaveIdea = async (ideaName, index) => {
    if (ideaName.trim()) {
      const newIdeaData = { idea: ideaName };

      const docRef = doc(db, `users/${userId}/ideas`, new Date().getTime().toString());
      await setDoc(docRef, newIdeaData);

      // Update the specific project's saved state
      setProjectSaved((prev) => {
        const updated = [...prev];
        updated[index] = true; // Mark the specific idea as saved
        return updated;
      });

      setTimeout(() => {
        setProjectSaved((prev) => {
          const updated = [...prev];
          updated[index] = false; // Reset after 5 seconds
          return updated;
        });
      }, 5000);
    }
  };

  return (
    <Card>
      <VuiBox display="flex" justifyContent="space-between" alignItems="center" mb="22px">
        <VuiTypography variant="lg" color="white">
          AI App Tool
        </VuiTypography>
      </VuiBox>

      <VuiBox>
        {loading ? (
          <CircularProgress />
        ) : (
          <VuiBox mt={3}>
            {fullResponse ? (
              <Button style={{ color: 'white' }} variant="contained" onClick={resetState}>
                Reset
              </Button>
            ) : (
              <VuiBox display="flex" justifyContent="space-between" alignItems="center">
                <Button style={{ color: 'white' }} variant="contained" onClick={handleCreateProject}>
                  Generate App Ideas
                </Button>
              </VuiBox>
            )}
          </VuiBox>
        )}

        {!loading && !fullResponse && showTable && (
          <VuiBox className="custom-scrollbar" color="text" mt={3} style={{ maxHeight: '400px', overflowY: 'auto' }}>
            {ideas.map((idea, index) => (
              <Card key={index} style={{ backgroundColor: '#1A1A1A', marginBottom: '16px', padding: '16px' }}>
                <VuiBox display="flex" justifyContent="space-between" alignItems="center">
                  <VuiTypography variant="body1" color="white" fontWeight="medium" style={{ flexGrow: 1 }}>
                    {idea.name}
                  </VuiTypography>
                  <VuiBox display="flex" alignItems="center">
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: copiedIndex === index ? 'green' : 'white',
                        color: copiedIndex === index ? 'white' : 'black',
                      }}
                      onClick={() => handleCopyIdea(idea.name, index)}
                    >
                      {/* Conditional rendering based on isMobile */}
                      {isMobile ? (
                        <FaCopy />  // Show the icon if on mobile
                      ) : (
                        copiedIndex === index ? 'Copied' : 'Copy Idea' // Show text otherwise
                      )}
                    </Button>

                    <Button
                      variant="contained"
                      style={{
                        marginLeft: '10px',
                        backgroundColor: projectSaved[index] ? 'green' : 'blue', // Use the array to determine color
                        color: 'white',
                      }}
                      onClick={() => handleSaveIdea(idea.name, index)}
                    >
                       {isMobile ? (
                        <IoSaveSharp />
                      ) : (
                        projectSaved[index] ? 'Saved Idea' : 'Save Idea'
                      )}
                    </Button>
                  </VuiBox>
                </VuiBox>
                {idea.description && (
                  <VuiTypography variant="body2" color="white" style={{ marginTop: '8px' }}>
                    {idea.description}
                  </VuiTypography>
                )}
              </Card>
            ))}
          </VuiBox>
        )}

        <Dialog open={confirmDialogOpen}>
          <DialogContent>
            <DialogContentText>You have unsaved changes. Are you sure you want to discard them and create a new project?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setConfirmDialogOpen(false)}>Cancel</Button>
            <Button onClick={handleDiscard} color="primary" disabled={loading}>
              Discard
            </Button>
          </DialogActions>
        </Dialog>
      </VuiBox>
    </Card>
  );
};

export default AIAppToolPage;