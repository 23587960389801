// Import PropTypes for type checking
import PropTypes from "prop-types";

function VideoIcon({ size }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="4" fill="#fc3837" /> {/* Red background */}
      <path
        d="M8 5C7.44772 5 7 5.44772 7 6V18C7 18.5523 7.44772 19 8 19H14C14.5523 19 15 18.5523 15 18V6C15 5.44772 14.5523 5 14 5H8ZM9 17V7H13V17H9ZM17.2929 10.2929C17.6834 10.6834 17.6834 11.3166 17.2929 11.7071L15.5858 13.4142C15.1953 13.8047 14.5621 13.8047 14.1716 13.4142C13.7811 13.0237 13.7811 12.3905 14.1716 12L15.2929 10.8787C15.6834 10.4882 16.3166 10.4882 16.7071 10.8787L17.2929 10.2929Z"
        fill="white" // White color for the video icon
      />
    </svg>
  );
}

// Setting default values for the props of VideoIcon
VideoIcon.defaultProps = {
  size: "24px",
};

// Typechecking props for the VideoIcon
VideoIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default VideoIcon;
