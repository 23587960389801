import React, { useEffect, useState } from "react";
import { Card, Button } from "@mui/material"; // Import Button from MUI
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import gif from "assets/images/cardimgfree.png";
import { useNavigate } from "react-router-dom"; // For navigation
import { getAuth, sendPasswordResetEmail } from "firebase/auth"; // Import auth and sendPasswordResetEmail
import { getFirestore, doc, getDoc } from "firebase/firestore"; // Firestore functions

// Initialize Firebase services
const auth = getAuth(); // Initialize Firebase Authentication
const db = getFirestore(); // Initialize Firestore

const WelcomeMark = () => {
  const [userName, setUserName] = useState(""); // State to store the user's name
  const [userEmail, setUserEmail] = useState(""); // State to store the user's email

  const navigate = useNavigate(); // React Router's navigation hook

  useEffect(() => {
    // Fetch user data from Firestore
    const fetchUserData = async () => {
      try {
        const user = auth.currentUser; // Get the current authenticated user
        if (user) {
          // Set the user's email directly from the auth object
          setUserEmail(user.email); // Directly set the email from auth

          const userId = user.uid; // Get user ID
          const userDocRef = doc(db, "users", userId); // Create reference to the user's document
          const userDoc = await getDoc(userDocRef); // Fetch the document

          if (userDoc.exists()) {
            const data = userDoc.data();
            setUserName(data.Name); // Set the user's name in the state
          } else {
            console.error("User document not found");
          }
        }
      } catch (error) {
        console.error("Error fetching user document:", error);
      }
    };

    fetchUserData();
  }, []);

  // Handle click for the "Tap to start working" button
  const handleStartWorking = () => {
    window.open("https://billing.stripe.com/p/login/cN2cPd5tvdio5lC8ww", "_blank"); // Open the Stripe login in a new tab
  };

  // Handle password reset
  const handlePasswordReset = async () => {
    if (!userEmail) {
      alert("No email found for the user.");
      return;
    }

    try {
      await sendPasswordResetEmail(auth, userEmail);
      alert(`Password reset link sent to ${userEmail}`);
    } catch (error) {
      console.error("Error sending password reset email:", error);
      alert("Error sending password reset email. Please try again later.");
    }
  };

  return (
    <Card
      sx={{
        height: "340px",
        py: "32px",
        backgroundImage: `url(${gif})`,
        backgroundSize: "cover",
        backgroundPosition: "50%",
        // Add responsive styles here
        '@media (max-width: 600px)': {
          height: '250px', // Adjust height for mobile
          py: '16px', // Adjust padding for mobile
        },
        '@media (min-width: 601px)': {
          height: '340px', // Height for desktop
          py: '32px', // Padding for desktop
        }
      }}
    >
      <VuiBox height="100%" display="flex" flexDirection="column" justifyContent="space-between">
        <VuiBox>
          <VuiTypography color="white" variant="h3" fontWeight="bold" mb="18px" sx={{
            // Add responsive styles for typography
            '@media (max-width: 600px)': {
              fontSize: '1.5rem', // Smaller font for mobile
            },
            '@media (min-width: 601px)': {
              fontSize: '2rem', // Larger font for desktop
            },
          }}>
            {userName ? userName : " "} {/* Dynamically display the user's name */}
          </VuiTypography>
          <VuiTypography color="white" variant="h3" fontWeight="bold" mb="18px" sx={{
            // Add responsive styles for typography
            '@media (max-width: 600px)': {
              fontSize: '1.5rem', // Smaller font for mobile
            },
            '@media (min-width: 601px)': {
              fontSize: '2rem', // Larger font for desktop
            },
          }}>
            {userEmail ? userEmail : " "} {/* Dynamically display the user's email */}
          </VuiTypography>

          {/* Button to send password reset link */}
          <Button
            onClick={handlePasswordReset} // Call handlePasswordReset on click
            variant="outlined"
            color="primary"
            sx={{
              mt: 2,
              backgroundColor: 'blue',
              width: { xs: '100%', md: '300px' }, // Responsive width

            }}
          >
            <h3>
              Send Reset Password Link
            </h3>
          </Button>
          <div>
            <Button
              onClick={handleStartWorking} // Call the handleStartWorking function on click
              variant="outlined"
              color="primary"
              sx={{
                mt: 2,
                backgroundColor: 'blue',
                width: { xs: '100%', md: '300px' }, // Responsive width
              }}
            >
              <h3>
                Check CodeGrow Plan
              </h3>
            </Button>
          </div>
        </VuiBox>
      </VuiBox>
    </Card>
  );
};

export default WelcomeMark;
