import JSZip from "jszip";
import { saveAs } from "file-saver";

// Complete icon size list including 20pt, 29pt, 38pt, 40pt, 60pt, 64pt, 76pt, 83.5pt, and 1080x1080 with 2x and 3x variants
const iconSizes = [
  { name: "Icon-App-20x20@1x.png", size: 20 },
  { name: "Icon-App-20x20@2x.png", size: 40 },
  { name: "Icon-App-20x20@3x.png", size: 60 },
  { name: "Icon-App-29x29@1x.png", size: 29 },
  { name: "Icon-App-29x29@2x.png", size: 58 },
  { name: "Icon-App-29x29@3x.png", size: 87 },
  { name: "Icon-App-38x38@2x.png", size: 76 },
  { name: "Icon-App-38x38@3x.png", size: 114 },
  { name: "Icon-App-40x40@1x.png", size: 40 },
  { name: "Icon-App-40x40@2x.png", size: 80 },
  { name: "Icon-App-40x40@3x.png", size: 120 },
  { name: "Icon-App-60x60@2x.png", size: 120 },
  { name: "Icon-App-60x60@3x.png", size: 180 },
  { name: "Icon-App-64x64@2x.png", size: 128 },
  { name: "Icon-App-64x64@3x.png", size: 192 },
  { name: "Icon-App-76x76@2x.png", size: 152 },
  { name: "Icon-App-76x76@3x.png", size: 228 },
  { name: "Icon-App-83.5x83.5@2x.png", size: 167 },
  { name: "Icon-App-1080x1080.png", size: 1080 },
];

// Function to resize the image and generate a zip file with all required icon sizes
const imageResizeAndZip = async (imageFile) => {
  const zip = new JSZip();
  const img = await createImageBitmap(imageFile); // Convert image file to a bitmap

  for (const { name, size } of iconSizes) {
    // Create a canvas element for each size
    const canvas = document.createElement("canvas");
    canvas.width = size;
    canvas.height = size;
    const ctx = canvas.getContext("2d");

    // Draw the image onto the canvas and resize it
    ctx.drawImage(img, 0, 0, size, size);

    // Convert the canvas to a blob (PNG format)
    const blob = await new Promise((resolve) => {
      canvas.toBlob(resolve, "image/png");
    });

    // Add the resized image to the zip file
    if (blob) {
      zip.file(name, blob);
    }
  }

  // Generate the zip file as a Blob and return it
  return zip.generateAsync({ type: "blob" });
};

// Export the function for use in other components
export default imageResizeAndZip;
