import React, { useEffect, useState } from "react";
import { Card, Icon } from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import gif from "assets/images/cardimgfree.png";
import { useNavigate } from "react-router-dom"; // For navigation
import { doc, getDoc } from "firebase/firestore"; // Firestore functions
import { getAuth } from "firebase/auth"; // Import auth from firebase/auth
import { getFirestore } from "firebase/firestore"; // Import firestore from firebase/firestore

// Initialize Firebase services
const auth = getAuth(); // Initialize Firebase Authentication
const db = getFirestore(); // Initialize Firestore

const WelcomeMark = () => {
  const [userName, setUserName] = useState(""); // State to store the user's name
  const navigate = useNavigate(); // React Router's navigation hook

  useEffect(() => {
    // Fetch user data from Firestore
    const fetchUserName = async () => {
      try {
        const user = auth.currentUser; // Get the current authenticated user
        if (user) {
          const userId = user.uid; // Get user ID
          const userDocRef = doc(db, "users", userId); // Create reference to the user's document
          const userDoc = await getDoc(userDocRef); // Fetch the document

          if (userDoc.exists()) {
            const data = userDoc.data();
            setUserName(data.Name); // Set the user's name in the state
          } else {
            console.error("User document not found");
          }
        }
      } catch (error) {
        console.error("Error fetching user document:", error);
      }
    };

    fetchUserName();
  }, []);

  // Handle click for the "Tap to start working" button
  const handleStartWorking = () => {
    navigate("/ai-app-tool"); // Navigate to the /ai-app-tool page
  };

  return (
    <Card
      sx={{
        height: "340px",
        py: "32px",
        backgroundImage: `url(${gif})`,
        backgroundSize: "cover",
        backgroundPosition: "50%",
      }}
    >
      <VuiBox height="100%" display="flex" flexDirection="column" justifyContent="space-between">
        <VuiBox>
          <VuiTypography color="text" variant="button" fontWeight="regular" mb="12px">
            Welcome back,
          </VuiTypography>
          <VuiTypography color="white" variant="h3" fontWeight="bold" mb="18px">
            {userName ? userName : " "} {/* Dynamically display the user's name */}
          </VuiTypography>
          <VuiTypography color="text" variant="h6" fontWeight="regular" mb="auto">
            Glad to see you again!
          </VuiTypography>
        </VuiBox>
        <VuiTypography
          component="a"
          onClick={handleStartWorking} // Call the handleStartWorking function on click
          variant="button"
          color="white"
          fontWeight="regular"
          sx={{
            mr: "5px",
            display: "inline-flex",
            alignItems: "center",
            cursor: "pointer",

            "& .material-icons-round": {
              fontSize: "1.125rem",
              transform: `translate(2px, -0.5px)`,
              transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
            },

            "&:hover .material-icons-round, &:focus  .material-icons-round": {
              transform: `translate(6px, -0.5px)`,
            },
          }}
        >
          Tap to get creative
          <Icon sx={{ fontWeight: "bold", ml: "5px" }}>arrow_forward</Icon>
        </VuiTypography>
      </VuiBox>
    </Card>
  );
};

export default WelcomeMark;
