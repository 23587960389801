// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyBjHxPsojssJnBZPb5itAb74N0lB47rXi0",
    authDomain: "codegrow-e0300.firebaseapp.com",
    projectId: "codegrow-e0300",
    storageBucket: "codegrow-e0300.appspot.com",
    messagingSenderId: "590291518325",
    appId: "1:590291518325:web:f6a2ea35a13912f254493c",
    measurementId: "G-QG90HEYS1Q"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
