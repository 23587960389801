/* eslint-disable react/prop-types */
import React from "react"; 
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";

// Import the Canva templates data directly
import FlippaTemplatesData from './FlippaTemplates.json';

function FlippaTemplatesTable() {
  // Create rows directly from the JSON data, no hierarchy or subtables
  const rows = FlippaTemplatesData.map(template => ({
    "Title": (
      <VuiTypography
        variant="button"
        color="white"
        fontWeight="medium"
        style={{ cursor: "pointer", textDecoration: "none" }}
      >
        {template.title}
      </VuiTypography>
    ),
    "Category": (
      <VuiTypography variant="button" color="white" fontWeight="medium">
        {template.category}
      </VuiTypography>
    ),
    "Action": (
      <VuiButton
        component="a"
        href={template.link}
        target="_blank"
        rel="noopener noreferrer"
        color="primary"
        size="small"
        variant="contained"
      >
        View
      </VuiButton>
    ),
  }));

  return {
    columns: [
      { name: "Title", align: "left" },
      { name: "Category", align: "left" },
      { name: "Action", align: "center" },
    ],
    rows, // Return the rows created from the flat JSON data
  };
}

export default FlippaTemplatesTable;
