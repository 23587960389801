import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import VuiBox from "components/VuiBox";
import { useVisionUIController, setLayout } from "context";
import { useAuth } from "context/AuthContext";
import { Snackbar, Alert, Modal } from "@mui/material";
import axios from 'axios';
import PricingTable from '../../../components/PricingTable'; // Adjust the path as necessary
import { useMediaQuery } from '@mui/material';
import VuiButton from "components/VuiButton"; // Import Button if needed
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import dialog from "assets/theme/components/dialog";

function DashboardLayout({ children }) {
  const [controller, dispatch] = useVisionUIController();
  const { miniSidenav } = controller;
  const { pathname } = useLocation();
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [isProUser, setIsProUser] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openBreakdownModal, setOpenBreakdownModal] = useState(false); // New state for package breakdown modal
  const isMobile = useMediaQuery('(max-width:600px)');

  const checkProAccount = async (userEmail) => {
    try {
      const response = await axios.post('https://checksubscription-wkmofyogxa-uc.a.run.app', { email: userEmail });
      setIsProUser(response.data.active);
    
      if (!response.data.active) {
        setOpenModal(true);
      } else {
        console.log("User is subscribed to these products:", response.data.products);
    
        // Check which subscription the user has by product ID
        if (response.data.products.includes("prod_R4OZRuMOJn2RXX")) {
          console.log("User has the first subscription (prod_R4OZRuMOJn2RXX)");
          setOpenModal(true);

        } 
        if (response.data.products.includes("prod_R4OaST7dmwUbVR")) {
          console.log("User has the second subscription (prod_R4OaST7dmwUbVR)");
        }
      }
    } catch (error) {
      console.error('Error checking subscription:', error);
      if (error.response && error.response.status === 404) {
        setOpenModal(true);
      }
      setIsProUser(false);
    }
  };

  useEffect(() => {
    if (currentUser) {
      checkProAccount(currentUser.email);
    }
  }, [currentUser]);

  const [openSnackbar, setOpenSnackbar] = useState(false);

  useEffect(() => {
    setLayout(dispatch, "dashboard");
    if (!currentUser) {
      setOpenSnackbar(true);
      navigate("/authentication/sign-in");
    }
  }, [currentUser, dispatch, navigate]);

  const handleLogout = () => {
    setOpenModal(false);
    navigate("/authentication/logout");
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    navigate("/dashboard");
  };

  const handleUpgrade = () => {
    setOpenModal(false);
    navigate("/billing");
  };

  

  const handleViewPackageBreakdown = () => {
    setOpenBreakdownModal(true); // Open breakdown modal when the button is clicked
  };

  const handleCloseBreakdownModal = () => {
    setOpenBreakdownModal(false);
  };

  return (
    <>
      <VuiBox
        sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
          p: 3,
          position: "relative",
          minHeight: "calc(100vh - 0px)",
          [breakpoints.up("xl")]: {
            marginLeft: miniSidenav ? pxToRem(120) : pxToRem(274),
            transition: transitions.create(["margin-left", "margin-right"], {
              easing: transitions.easing.easeInOut,
              duration: transitions.duration.standard,
            }),
          },
        })}
      >
        {children}
      </VuiBox>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleCloseSnackbar} severity="warning">
          You need to log in to access this content.
        </Alert>
      </Snackbar>

      {/* Main Modal */}
      {openModal && (
        // <div style={{
        //   position: 'fixed',
        //   top: isMobile ? '60%' : '50%',
        //   left: '50%',
        //   transform: 'translate(-50%, -50%)',
        //   background: '#fff',
        //   padding: '20px',
        //   borderRadius: '8px',
        //   zIndex: 1000,
        //   boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        //   height: isMobile ? '80%' : '',
        //   width: isMobile ? '100%' : '',
        // }}>
        <Dialog
          open={openModal}
          onClose={handleCloseBreakdownModal}
          maxWidth={ isMobile? "100vw" : '50vw' }
          fullWidth
      
          PaperProps={{
            style: {  width: isMobile? "100vw" :'40vw', borderRadius:'15px',  padding: isMobile? "15px" : "30px",backgroundColor: 'white', color: 'black' }, // Customize modal background color
          }}
        >

          <button
            onClick={handleCloseModal}
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              background: 'transparent',
              border: 'none',
              fontSize: '16px',
              cursor: 'pointer'
            }}
          >
            &#x2715; {/* Unicode for the 'X' symbol */}
          </button>
          <h2 style={{marginTop:'5px'}}>You don't have a Pro Yearly account</h2>
          <p>Upgrade to a Pro Yearly account to get access to the free ready to release IOS applications.</p>
          <br/>
          <VuiButton style={{ color: 'blue' }} onClick={handleUpgrade}>
           Click Here To Upgrade
          </VuiButton>
          <br/>
          <VuiButton style={{ color: 'blue' }} onClick={handleViewPackageBreakdown}>
            View Package Breakdown
          </VuiButton>

          </Dialog>

      )}

          {/* Package Breakdown Modal */}
          {openBreakdownModal && (
            <Dialog
              open={openModal}
              onClose={handleCloseBreakdownModal}
              maxWidth="80vw" // Adjust the width as needed
              fullWidth
              PaperProps={{
                style: { backgroundColor: '#333', color: 'white', height: '80vh' }, // Customize modal background color
              }}
            >

              <div style={{
                background: '#fff',
                padding: '20px',
                borderRadius: '8px',
                overflowY: 'auto',
                position: 'relative',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                marginTop: isMobile ? '0px' : '0px',
              }}>
                <button
                  onClick={handleCloseBreakdownModal}
                  style={{
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                    background: 'transparent',
                    border: 'none',
                    fontSize: '16px',
                    cursor: 'pointer'
                  }}
                >
                  &#x2715; {/* Unicode for the 'X' symbol */}
                </button>
                <h2 style={{ color: 'black' }}>Package Breakdown</h2>
                <br />

                {/* YouTube video */}
                <div style={{ marginBottom: '20px' }}>
                  <iframe
                    width="100%"
                    height="315"
                    src="https://www.youtube.com/embed/JaGNs45A_c8?si=mWIUqcRjCL_p1ReG" // Replace with your actual video link
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </div>

                {/* Table with package comparison */}
                <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'space-evenly', marginBottom: '20px' }}>
                  <div>
                    <h2 style={{ color: 'black' }}>Monthly Package</h2>
                    <ul style={{ listStyleType: 'none', paddingLeft: 0, backgroundColor: '#FC3837', color: 'white', borderRadius: '10px', padding: '10px' }}>
                      {[
                        'Unlimited Access To Full Course',
                        'AI App Maker',
                        'Canva Design Templates',
                        'Flippa Sale Templates',
                        'Projects Manager',
                        'Ai Chrome Extensions',
                        'App Store Icon Size Generator',
                      ].map((feature, index) => (
                        <li key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                          <span style={{ color: 'white', marginRight: '8px' }}>✔</span> {feature}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <br />
                  <div>
                    <h2 style={{ color: 'black' }}>Yearly Package</h2>
                    <ul style={{ listStyleType: 'none', paddingLeft: 0, backgroundColor: '#FC3837', color: 'white', borderRadius: '10px', padding: '10px' }}>
                      {[
                        '1:1 Coaching',
                        'Free Ready To Release IOS Apps',
                        'Unlimited Access To Full Course',
                        'AI App Maker',
                        'Canva Design Templates',
                        'Flippa Sale Templates',
                        'Projects Manager',
                        'Ai Chrome Extensions',
                        'App Store Icon Size Generator'
                      ].map((feature, index) => (
                        <li key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                          <span style={{ color: 'white', marginRight: '8px' }}>✔</span> {feature}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                {/* List of features with checkmarks */}

              </div>
            </Dialog>
          )}
        </>
      );
}

      DashboardLayout.propTypes = {
        children: PropTypes.node.isRequired,
};

      export default DashboardLayout;
